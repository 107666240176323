import * as yup from "yup";

const communicationAddressSchema = yup.object().shape({
  address: yup.string().required("Address is required"),
  pincode: yup.string().required("Pincode is required"),
  city_val: yup.string().required("City is required"),
  city_name: yup.string().required("City is required"),
  state_name: yup.string().required("State is required"),
  state_val: yup.string().required("State is required"),
});
export const relianceCommunicationAddressSchema = yup.object().shape({
  address: yup.string().required("Address is required"),
  pincode: yup.string().required("Pincode is required"),
  city_val: yup.string().required("City is required"),
  city_name: yup.string().required("City is required"),
  state_name: yup.string().required("State is required"),
  state_val: yup.string().required("State is required"),
  is_reg_same_as_com: yup.string().required(),
  reg_address: yup.string().when("is_reg_same_as_com", {
    is: "N",
    then: yup.string().required("Registration address is required"),
  }),
  reg_pincode: yup.string().when("is_reg_same_as_com", {
    is: "N",
    then: yup.string().required("Registration pincode is required"),
  }),
  reg_city_val: yup.string().when("is_reg_same_as_com", {
    is: "N",
    then: yup.string().required("Registration city is required"),
  }),
  reg_city_name: yup.string().when("is_reg_same_as_com", {
    is: "N",
    then: yup.string().required("Registration city is required"),
  }),
  reg_state_name: yup.string().when("is_reg_same_as_com", {
    is: "N",
    then: yup.string().required("Registration state is required"),
  }),
  reg_state_val: yup.string().when("is_reg_same_as_com", {
    is: "N",
    then: yup.string().required("Registration state is required"),
  }),
});
export default communicationAddressSchema;

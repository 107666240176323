import React, { useEffect, useState } from "react";
import {
  CarDescription,
  CarName,
  CarVariant,
  EditButton,
  SectionWrapper,
  Wrapper,
} from "./CarDetails.style";
import car from "./../../../../../../assets/images/Car.svg";
import { useSelector } from "react-redux";
import {
  fallBackSelector,
  noNumberSelector,
  setFallbackToLanding,
  setFallbackToNoCar,
} from "../../../../Landing.slice";
import ArrowLeft from "@material-ui/icons/ArrowBack";
import "styled-components/macro";
import { useDispatch } from "react-redux";
import EditCarDetails from "../EditCarDetails/EditCarDetails";
const CarDetails = ({ setShow, edit, setEdit }) => {
  const { BRAND_TITLE, MODEL_NAME, TYPE_OF_FUEL, VARIANT_NAME } =
    useSelector(noNumberSelector);
  const { regNo } = useSelector((state) => state.input);
  const dispatch = useDispatch();

  if (edit)
    return (
      <>
        <EditCarDetails setEdit={setEdit} setShow={setShow} />
      </>
    );

  return (
    <>
      <Wrapper>
        <SectionWrapper>
          <div
            css={`
              padding: 20px 0;
              display: flex;
              align-items: ${regNo ? "left" : "center"};
              flex-direction: ${regNo && "column"};
              & p {
                margin: 0;
                font-weight: 600;
              }
            `}
            onClick={() => {
              if (regNo) {
                return;
              }
              dispatch(setFallbackToNoCar({ lastActive: 4 }));
              setShow(false);
            }}
          >
            {!regNo ? (
              <>
                <ArrowLeft />
                <div
                  css={`
                    margin-left: 10px;
                    font-size: 24px;
                    font-weight: 600;
                  `}
                >
                  Your Car
                </div>
              </>
            ) : (
              <>
                <p
                  css={`
                    color: var(--dark-grey-1);
                    margin-bottom: 12px;
                  `}
                >
                  We fetched the details for
                </p>
                <p
                  css={`
                    color: var(--tertiary-color);
                    font-weight: 700;
                    margin-bottom: 10px;
                    font-size: 20px;
                  `}
                >
                  {regNo}
                </p>
                <p
                  css={`
                    color: var(--primary-color);
                    cursor: pointer;
                    font-size: 14px;
                  `}
                  onClick={(e) => {
                    setEdit(true);
                    e.stopPropagation();
                  }}
                >
                  Did we get it right?
                </p>
              </>
            )}
          </div>
        </SectionWrapper>
        <SectionWrapper>
          <img src={car} alt='car'></img>
        </SectionWrapper>
        <SectionWrapper>
          <CarDescription>
            <CarName>{BRAND_TITLE + ", " + MODEL_NAME}</CarName>
            <CarVariant>{TYPE_OF_FUEL + ", " + VARIANT_NAME}</CarVariant>
            <div
              css={`
                font-size: 12px;
                color: var(--primary-color);
                font-weight: 600;
                cusror: pointer;
              `}
              onClick={() => {
                setShow(false);
                dispatch(setFallbackToLanding());
              }}
            >
              Not Your Car?
            </div>
          </CarDescription>
        </SectionWrapper>
      </Wrapper>
      <div
        css={`
          display: none;
          padding: 70px 0px 30px;

          @media (max-width: 576px) {
            display: flex;
            justify-content: space-between;
            margin: 0 auto;
            width: 320px;
            align-items: center;
          }
        `}
      >
        <div>
          <span
            css={`
              @media (max-width: 576px) {
                font-weight: 600;
                font-size: 18px;
              }
            `}
          >
            {" "}
            {BRAND_TITLE + ", " + MODEL_NAME}{" "}
          </span>
          <span
            css={`
              @media (max-width: 576px) {
                font-weight: 600;
                font-size: 16px;
                color: var(--dark-grey-1);
              }
            `}
          >
            {TYPE_OF_FUEL + ", " + VARIANT_NAME}
          </span>
        </div>{" "}
        <div
          css={`
            @media (max-width: 576px) {
              font-weight: 600;
              color: var(--secondary-color);
            }
          `}
          onClick={() => {
            dispatch(setFallbackToNoCar({ lastActive: 4 }));
            setShow(false);
          }}
        >
          Edit
        </div>
      </div>
    </>
  );
};

export default CarDetails;

import styled from "styled-components";
export const AddonDetail = styled.div`
  width: 100%;
  gap: 16px;
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  @media (max-width: 576px) {
    display: none;
  }
`;
export const AddonLabel = styled.div`
  background: #eefbff;
  border: 1px solid #c4f1ff;
  padding: 6px 12px;
  justify-content: space-between;
  border-radius: 3px;
  & .name {
    color: var(--primary-color);
    margin-right: 16px;
    display: inline-block;
  }
  & .premium {
    color: var(--tertiary-color);
    display: inline-block;
  }
  @media (max-width: 576px) {
    background: #fff;
    padding: 0;
    border: none;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    & .name {
      font-size: 12px;
    }
    & .premium {
      font-size: 12px;
      font-weight: 600;
    }
  }
`;
export const QuoteCardWrapper = styled.div`
  min-height: 135px;
  margin-bottom: 16px;
  width: 100%;
  order: ${(props) => props.flexOrder};
  border: 1px solid #eaf1f6;
  padding: 0 20px;
  position: relative;
  & .plan-type {
    position: absolute;
    top: -10px;
    background: var(--primary-color);
    color: #fff;
    padding: 4px 8px;
    left: 0;
    font-size: 14px;
    &:after {
      position: absolute;
      content: "";
      right: -26px;
      top: 0;
      border: 14px solid var(--primary-color);
      border-right-color: transparent;
    }
  }
  @media (max-width: 767px) {
    height: auto;
    padding: 16px;
    min-height: unset;
  }
`;
export const MobileQuoteCard = styled.div`
  @media (max-width: 576px) {
    display: flex;
    position: relative;
  }
  display: none;
  flex-direction: column;
`;

export const QuoteDetails = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 135px;
  @media (max-width: 576px) {
    display: none;
  }
`;
export const QuoteDataWrapper = styled.div`
  flex: 1 1;
`;
export const LogoWrapper = styled(QuoteDataWrapper)`
  & img {
    width: 160px;
    height: 50px;

    @media (max-width: 1199px) {
      width: 136px;
      height: 40px;
    }
  }
`;
export const PremiumWrapper = styled(QuoteDataWrapper)`
  display: flex;
  flex-direction: column;
  text-align: center;
  & .view_plan {
    background: #fff;
    border: none;
    display: inline-block;
    color: var(--primary-color);
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
`;

export const Label = styled.div`
  color: var(--dark-grey-1);
`;
export const Value = styled.div`
  font-weight: 700;
  color: var(--tertiary-color);
  & small {
    color: var(--primary-color);
    margin-left: 6px;
  }
`;
export const BuyNowButton = styled.button`
  padding: 14px 0;
  display: flex;
  margin: 0 auto;
  width: 174px;
  justify-content: space-evenly;
  border: 1px solid var(--secondary-color);
  background: #fff;
  align-items: center;
  margin-bottom: 12px;
  & .label {
    font-size: 12px;
    font-weight: 600;
    color: var(--tertiary-color);
  }
  & .price {
    font-size: 18px;
    color: var(--secondary-color);
    font-weight: 700;
  }
  &:hover {
    background: var(--secondary-color);
    & .label,
    & .price {
      color: #fff;
    }
  }
  @media (max-width: 576px) {
    margin: 0;
    width: 150px;
    padding: 12px 0;
    & .label {
      font-size: 12px;
      font-weight: 600;
      color: var(--tertiary-color);
    }
    & .price {
      font-size: 14px;
      color: var(--secondary-color);
      font-weight: 700;
    }
  }
`;

import React from "react";
import TextField from "@material-ui/core/TextField";
import { InputLabel, styled } from "@material-ui/core";
const TextInput = ({
  label,
  size,
  error,
  maxLength,
  value,
  fixedPart,
  ...props
}) => {
  return (
    <>
      {props.type === "file" && (
        <>
          <InputLabel>{label}</InputLabel>
        </>
      )}
      <CustomField
        label={props.type !== "file" && label}
        size={size}
        variant={"outlined"}
        onBlur={(e) => {
          props.onBlur && props.onBlur(e);
          if (fixedPart) {
            if (e.target.value.indexOf(fixedPart) !== 0) {
              e.preventDefault();
              e.target.value = fixedPart;
            }
          }
        }}
        onKeyUp={(e) => {
          props.onKeyUp && props.onKeyUp(e);
          if (fixedPart) {
            if (e.target.value.indexOf(fixedPart) !== 0) {
              e.preventDefault();
              e.target.value = fixedPart;
            }
          }
        }}
        {...props}
        value={props.type !== "file" ? value || "" : undefined}
        error={Boolean(error)}
        autoComplete={false}
        inputProps={{
          maxLength,
          ...props?.inputProps,
        }}
        helperText={error}
        fullWidth
      />
    </>
  );
};

export default TextInput;

const CustomField = styled(TextField)({
  border: "0 !important",
  boxShadow: "none",
  "& label.Mui-error": {
    color: "rgba(0, 0, 0, 0.54)",
  },
  "& label.Mui-focused": {
    color: "var(--primary-color)",
  },
  "& label.MuiFormLabel-root": {
    fontSize: (props) => (props.size === "large" ? "1.25rem" : "16px"),
    top: (props) => (props.size === "large" ? "2px" : "-3px"),

    "@media (max-width:576px)": {
      top: "-4px",
    },
  },
  "& .MuiOutlinedInput-multiline": {
    padding: "12px 14px",
  },
  "& .MuiOutlinedInput-inputMultiline": {
    height: "70px !important",
  },
  "& label.Mui-focused, label.MuiFormLabel-filled": {
    fontSize: (props) => (props.size === "large" ? "16px" : "16px"),
    transform: (props) =>
      props.size === "medium" && "translate(14px, -4px) scale(0.75)",
    "@media (max-width:576px)": {
      transform: (props) =>
        props.size === "large"
          ? "translate(14px, -8px) scale(0.75)"
          : "translate(14px, -2px) scale(0.75)",
    },
  },

  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "var(--dark-grey-2)",
      borderRadius: "5px",
    },
    "&:hover fieldset": {
      borderColor: "var(--tertiary-color)",
    },
    "&.Mui-focused fieldset": {
      borderColor: "var(--primary-color)",
    },
    "&.Mui-focused.Mui-error fieldset, &.Mui-error fieldset": {
      borderColor: "rgb(244, 67, 54)",
    },
    "& input:focus": {
      color: "var(--primary-color)",
    },
  },
  "& .MuiInputBase-input": {
    height: (props) =>
      props.size === "large"
        ? "64px"
        : props.size === "medium"
        ? "50px"
        : "32px",
    boxSizing: "border-box",
    fontSize: "1.25rem",

    "@media (max-width:576px)": {
      height: (props) => (props.size === "large" ? "54px" : "54px"),
    },
  },
});

import React, { useEffect, useState } from "react";
import { Button } from "../../../../../../components/Button/Button";
import RadioButton from "../../../../../../components/RadioButton/RadioButton";
import TextInput from "../../../../../../components/TextInput/TextInput";
import getDateInWords from "../../../../../../utils/getDateInWords";
import { ButtonWrapper, FormWrapper, TandC, Wrapper } from "./ExpiryForm.style";
import "styled-components/macro";
import { Question } from "../../../NoCarNumber/NoCarNumber.style";
import moment from "moment";
import "styled-components/macro";
import { useEnquiryMutation } from "../../../../../../services/CarServices";
import { noNumberSelector, setFormData } from "../../../../Landing.slice";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { onInputPhone } from "../../../../../../utils/onInput";
import { mobileNoExp } from "../../../../../../utils/regularExpressions";
const ExpiryForm = () => {
  const { formData } = useSelector((state) => state.input);
  const carDetails = useSelector(noNumberSelector);
  const { submitForm, isLoading, isSuccess, quote_id } = useEnquiryMutation();
  const dispatch = useDispatch();
  const onSubmit = () => {
    if (!formData?.mobile_no) {
      setError("Mobile no is required");
      return;
    }
    if (!mobileNoExp.test(formData?.mobile_no)) {
      setError("Please enter a valid mobile no");
      return;
    }
    submitForm({ formData, carDetails });
  };
  const [error, setError] = useState();

  return (
    <>
      <Wrapper>
        <FormWrapper>
          <Question
            css={`
              @media (max-width: 576px) {
                text-align: left;
              }
            `}
          >
            You are just a <span>step</span> Away !
          </Question>
          <RadioButton
            items={[
              { label: "Expired", value: "Y" },
              { label: "Not Expired", value: "N", defaultChecked: true },
            ]}
            defaultValue={formData.is_policy_expired}
            onChange={(value) => {
              if (value === "N") {
                dispatch(
                  setFormData({
                    ...formData,
                    is_policy_expired: value,
                    policy_expired_before_90_days: "N",
                  })
                );
                return;
              }
              dispatch(setFormData({ ...formData, is_policy_expired: value }));
            }}
            label={"Tell us if your previous policy has expired:"}
          />
          {formData.is_policy_expired === "Y" && (
            <RadioButton
              items={[
                { label: "Yes", value: "Y" },
                { label: "No", value: "N", defaultChecked: true },
                { label: "I Don't Know", value: "O" },
              ]}
              onChange={(value) => {
                dispatch(
                  setFormData({
                    ...formData,
                    policy_expired_before_90_days: value,
                  })
                );
              }}
              defaultValue={formData.policy_expired_before_90_days}
              label={`Did your policy expire before ${getDateInWords(
                moment().subtract(90, "d")
              )}?`}
            />
          )}
          {((formData.is_policy_expired === "Y" &&
            formData.policy_expired_before_90_days === "N") ||
            formData.is_policy_expired === "N") && (
            <RadioButton
              items={[
                { label: "Yes", value: "Y" },
                { label: "No", value: "N", defaultChecked: true },
              ]}
              onChange={(value) => {
                dispatch(
                  setFormData({ ...formData, is_claim_made_last_year: value })
                );
              }}
              defaultValue={formData.is_claim_made_last_year}
              label={`Have you made a claim in the previous year ?`}
            />
          )}

          <TextInput
            size={"medium"}
            label={"Mobile Number"}
            maxLength={10}
            onInput={onInputPhone}
            error={error}
            onChange={(e) =>
              dispatch(setFormData({ ...formData, mobile_no: e.target.value }))
            }
            value={formData.mobile_no}
            inputProps={{ maxLength: 10 }}
          />
          <ButtonWrapper>
            <Button primary fullWidth onClick={onSubmit}>
              View Quotes
            </Button>
            <TandC>
              By clicking I agree to all{" "}
              <a
                href={`${process.env.REACT_APP_BASE_REDIRECT_URL}/terms-and-conditions`}
              >
                T&C
              </a>
            </TandC>
          </ButtonWrapper>
        </FormWrapper>
      </Wrapper>
    </>
  );
};

export default ExpiryForm;

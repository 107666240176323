import React from "react";
import styled from "styled-components";

const Summary = ({ label, value, isFullWidth }) => {
  if (!value) return <></>;
  return (
    <>
      <SummaryWrapper isFullWidth={isFullWidth}>
        <Label>{label}</Label> &nbsp;:&nbsp; <Value>{value}</Value>
      </SummaryWrapper>
    </>
  );
};

export default Summary;
export const SummaryWrapper = styled.div`
  width: ${(props) => (props.isFullWidth ? "100%" : "calc(50% - 10px)")};
  display: flex;

  & div {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 4px;
  }
  @media (max-width: 991px) {
    width: 100%;
  }
`;
export const Label = styled.div`
  color: var(--dark-grey-1);
  white-space: nowrap;
`;
export const Value = styled.div`
  font-weight: 600;

  word-break: break-word;
`;

import styled from "styled-components";

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 350px;
  margin: 0 auto;
  gap: 16px;
  @media (max-width: 576px) {
    min-width: 320px;
  }
`;
export const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
export const ButtonWrapper = styled.div`
  margin-top: 16px;
`;
export const TandC = styled.p`
  margin-top: 12px;
  & a {
    text-decoration: none;
    color: var(--primary-color);
    cursor: pointer;
  }
`;

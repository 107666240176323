import { useMutation, useQuery, useQueryClient } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import api from "../api/api";
import { useAddonsFromUrl } from "../hooks/addonsFromUrl";
import useUrlQuery from "./../hooks/useUrlQuery";
import {
  setIDVRange,
  setNotGenerated,
  setQuoteEndPoints,
  setQuotes,
  updateAddons,
} from "../pages/QuotesPage/QuotesPage.slice";

export const useGetQuotesQuery = (endPoint) => {
  const dispatch = useDispatch();
  const { data, isLoading, isError, refetch } = useQuery(
    ["quotes", endPoint],
    async () => await api.get(endPoint),

    {
      onSuccess: (data) => {
        if (data.data.status === 500) {
          toast.info(
            <>
              <div className='quotes__error'>
                <img src={data.data.PRODUCT_DATA.LOGO_PATH} alt='logo'></img>
                <span>{data.data.PREMIUM_DATA.MESSAGE}</span>
              </div>
            </>,
            {
              icon: false,
              position: "bottom-right",
              autoClose: 5000,
              toastId: endPoint,
            }
          );
          dispatch(
            setNotGenerated({
              logo: data.data.PRODUCT_DATA.LOGO_PATH,
              message: data.data.PREMIUM_DATA.MESSAGE,
              product_data: data.data.PRODUCT_DATA,
              endPoint,
            })
          );
          return;
        }

        dispatch(
          setIDVRange({
            IDV_MIN: data?.data?.PREMIUM_DATA?.PREMIUM_DATA?.IDV_MIN,
            IDV_MAX: data?.data?.PREMIUM_DATA?.PREMIUM_DATA?.IDV_MAX,
          })
        );
        if (data?.data.status === 200) dispatch(setQuotes(data?.data));
      },
    }
  );

  const error = data?.data?.status === 500;

  return {
    premium_data: data?.data?.PREMIUM_DATA?.PREMIUM_DATA,
    product_data: data?.data?.PRODUCT_DATA,
    included_addons:
      data?.data?.PREMIUM_DATA?.PREMIUM_DATA?.ADDONS?.INCLUDED || {},
    available_addons:
      data?.data?.PREMIUM_DATA?.PREMIUM_DATA?.ADDONS?.CAN_OPT || {},
    isLoading,
    isError: isError || error,
    refetch,
  };
};
export const useGetAddonsQuery = () => {
  const dispatch = useDispatch();
  const { data, isLoading } = useQuery(
    ["addons"],
    async () => await api.post("/api/GetAddons"),
    {
      onSuccess: (data) => {
        let addons_name_slug = {};

        data?.data?.forEach((item) => {
          addons_name_slug = {
            ...addons_name_slug,
            [item.ADD_ON_SLUG]: item.ADD_ON_NAME,
          };
        });
        dispatch(
          updateAddons({ ...addons_name_slug, NILL_DEPT: "Zero Depriciation" })
        );
      },
    }
  );

  return {
    isLoading,
    addons_list: data?.data || [],
  };
};
export const useFilterMutation = (isPF) => {
  // const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const prevAddon = useAddonsFromUrl(isPF);
  const { set } = useUrlQuery();
  const { mutate, isLoading, isSuccess, data } = useMutation(
    (req) => {
      return api.post("api/GetEligibleProducts", req);
    },
    {
      onSuccess: ({ data }) => {
        navigate(`/quotes?${set("quote_id", data?.response?.quote_id)}`, {
          replace: true,
        });
      },
    }
  );

  return {
    update: mutate,
    isLoading,
    quote_id: data?.data?.response?.quote_id,
    isSuccess,
  };
};
export const useGaragesQuery = ({ pincode, company_slug }) => {
  const { data, isLoading } = useQuery(
    ["garages", pincode],
    () =>
      api.post("api/GetGaragesByPincode", {
        PINCODE: pincode,
        COMPANY_SLUG: company_slug,
      }),
    { enabled: !!(pincode && pincode.length === 6) }
  );

  return { garages: data?.data?.response || [], isLoading };
};

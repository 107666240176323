import React, { useEffect, useState } from "react";
import PlanCard from "../Proposal/components/PlanCard/PlanCard";
import {
  FormCard,
  InputWrapper,
  ProposalFormWrapper,
  ProposalPageWrapper,
  SummaryDetailsWrapper,
} from "../Proposal/ProposalForm.style";
import "styled-components/macro";
import { useSelector } from "react-redux";
import {
  serialize,
  useFinalSubmitMutation,
  usePaymentQuery,
  useProposalEnquiry,
  useUpdateOrderMutation,
} from "../../services/ProposalServices";
import { CircularProgress } from "@material-ui/core";
import { Button } from "../../components/Button/Button";
import Check from "../../components/Checkbox/Checkbox";
import Loader from "../../components/Loader/Loader";
const DataComp = ({ label, value }) => {
  return (
    <>
      <div
        css={`
          display: flex;
          flex-direction: column;
          width: calc(50% - 10px);
          margin-bottom: 30px;
        `}
      >
        <div
          css={`
            font-size: 18px;
            color: var(--dark-grey-1);
            margin-bottom: 12px;
          `}
        >
          {label}
        </div>
        <div
          css={`
            font-size: 16px;
            font-weight: 600;
            line-height: 24px;
            white-space: pre-line;
          `}
        >
          {value}
        </div>
      </div>
    </>
  );
};

const ProposalSummary = () => {
  const { proposalData } = useSelector((state) => state.proposal);
  // const { isLoading, vehicle,  } = useProposalEnquiry();
  const { isLoading, vehicle, product } = useProposalEnquiry();
  const [checked, setChecked] = useState(false);
  const { data, isPaymentLoading, isResubmit } = usePaymentQuery();
  const {
    finalSubmit,
    isLoading: isSubmitting,
    finalSubmitData,
  } = useFinalSubmitMutation();
  console.log(finalSubmitData);
  const isOD = product?.POLICY_TYPE === "OD";
  const [trigger, setTrigger] = useState(false);
  const { updateOrder } = useUpdateOrderMutation();
  const onSubmit = (data) => {
    const { PG_FORM } = data;
    updateOrder();
    const { fields, method, url } = PG_FORM;
    if (method === "POST") {
      const form = document.createElement("form");
      Object.keys(fields).forEach((item) => {
        const input = document.createElement("input");
        input.name = item;
        input.value = fields[item];
        form.appendChild(input);
      });
      form.action = url;
      form.method = method;
      document.body.appendChild(form);
      form.submit();
      document.body.removeChild(form);
    } else {
      let query = "";
      if (fields) {
        query = serialize(fields);
      }
      if (query) {
        window.open(url + "?" + query, "_self");
      } else window.open(url, "_self");
    }
  };
  // useEffect(() => {
  //   if (!isSubmitting && finalSubmitData) {
  //     onSubmit(finalSubmitData);
  //   }
  // }, [finalSubmitData, isSubmitting]);
  if (isLoading || isPaymentLoading || !vehicle) return <></>;
  const { vehicle_make, vehicle_model, vehicle_variant, fuel_type } = vehicle;
  const {
    company_name,
    owner_type,
    first_name,
    last_name,
    email,
    mobile_no,
    gender,
    is_married,
    dob,
    pan_no,
    occupation,
    nominee_name,
    nominee_age,
    nominee_relation,
    address,
    pincode,
    city_name,
    state_name,
    registration_number,
    engine_number,
    chassis_number,
    is_financed,
    prev_insurer_name,
    prev_policy_no,
    financier_name,
    is_pa,
    reg_address,
    reg_city_name,
    reg_pincode,
    reg_state_name,
    tp_prev_insurer_name,
    tp_prev_policy_no,
    tp_existing_exp_date,
  } = proposalData;

  return (
    <>
      {" "}
      {isSubmitting && <Loader message='Resubmitting Proposal' />}
      {trigger && <Loader message='Redirecting to payment page' />}
      <ProposalPageWrapper>
        <PlanCard />
        <ProposalFormWrapper>
          <FormCard>
            <div
              css={`
                text-align: center;
                font-weight: 600;
                font-size: 22px;
                margin-bottom: 40px;
              `}
            >
              Important Details to review before payment
            </div>
            <SummaryDetailsWrapper>
              {proposalData?.owner_type !== "COMP" ? (
                <DataComp
                  value={`${first_name} ${last_name}\n${email}\n${mobile_no}\n${
                    pan_no ? "PAN: " + pan_no : ""
                  }`}
                  label={`Car Owner`}
                />
              ) : (
                <DataComp
                  value={`${company_name}\n${email}\n${mobile_no}\n${
                    pan_no ? "PAN: " + pan_no : ""
                  }`}
                  label={`Car Owner`}
                />
              )}
              {proposalData?.owner_type !== "COMP" && (
                <DataComp
                  value={`${gender === "M" ? "Male" : "Female"}, ${
                    is_married === "Y" ? "Married" : "Single"
                  } \n DOB: ${dob}\nOccupation: ${occupation}`}
                  label={`Personal Details`}
                />
              )}
              {proposalData?.owner_type !== "COMP" && (
                <DataComp
                  value={`${nominee_name}, ${nominee_age} \n ${nominee_relation}`}
                  label={`Nominee Details`}
                />
              )}
              <DataComp
                value={`${address}\n${city_name}, ${state_name} - ${pincode}`}
                label={`Communication Address`}
              />
              {proposalData?.is_reg_same_as_com === "N" && (
                <DataComp
                  value={`${reg_address}\n${reg_city_name}, ${reg_state_name} - ${reg_pincode}`}
                  label={`Communication Address`}
                />
              )}
            </SummaryDetailsWrapper>
          </FormCard>
          <FormCard>
            <SummaryDetailsWrapper>
              <DataComp
                value={`${vehicle_make} ${vehicle_model} ${vehicle_variant} \n ${fuel_type}`}
                label={`Your Car`}
              />
              <DataComp
                value={`${registration_number}`}
                label={`Registration Number`}
              />
              <DataComp value={`${engine_number}`} label={`Engine Number`} />
              <DataComp value={chassis_number} label={`Chassiss Number`} />
              <DataComp
                value={prev_insurer_name}
                label={`Previous Policy Insurer`}
              />
              <DataComp
                value={prev_policy_no}
                label={`Previous Policy Number`}
              />
              {isOD && (
                <>
                  <DataComp
                    value={tp_prev_insurer_name}
                    label={`TP Policy Insurer`}
                  />
                  <DataComp
                    value={tp_prev_policy_no}
                    label={`TP Policy Number`}
                  />
                  <DataComp
                    value={tp_existing_exp_date}
                    label={`TP Policy end date`}
                  />
                </>
              )}
              <DataComp
                value={is_financed === "N" ? "Car Not on Loan" : financier_name}
                label={`Loan Provider`}
              />
            </SummaryDetailsWrapper>
          </FormCard>
          <FormCard>
            <InputWrapper>
              <Check
                label={
                  "I declare that the information provided above is true and accept that if it is found to be false, it may impact claims. I authorize Yella Insurance Broking Pvt. Ltd. to represent me at insurance companies for my insurance needs."
                }
                onClick={(e) => {
                  setChecked(e.target.checked);
                }}
              />
            </InputWrapper>
            {checked && (
              <Button
                css={`
                  width: 70%;
                  margin: 24px auto 0;
                `}
                disabled={isLoading}
                primary
                onClick={(e) => {
                  e.preventDefault();
                  if (isResubmit) {
                    finalSubmit();
                  } else onSubmit(data);
                }}
              >
                {isLoading ? (
                  <CircularProgress size={"16px"} style={{ color: "#fff" }} />
                ) : (
                  "Proceed To Payment"
                )}
              </Button>
            )}
          </FormCard>
        </ProposalFormWrapper>
      </ProposalPageWrapper>
    </>
  );
};

export default ProposalSummary;

import styled, { keyframes } from "styled-components";
const showmore = keyframes`
  0% {
    opacity: 0;
    max-height: 300px;
  }
  100% {
    opacity: 1;
    max-height: 900px;
  }
`;
export const PlanCardWrapper = styled.div`
  display: inline-flex;
  flex-direction: column;
  width: 368px;
  justify-content: flex-start;
  align-item: flex-start;
  padding: 30px 30px;
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.02);
  border-radius: 3px;
  transition: all 0.5s;
  overflow-y: hidden;
  height: 100%;

  @media (max-width: 991px) {
    width: 100%;
  }
  &.less {
    max-height: 900px;
  }
  &.more {
    max-height: 300px;
  }
  position: relative;
  & .see {
    position: absolute;
    color: var(--primary-color);
    font-size: 14px;
    right: 12px;
    bottom: 6px;
    &.less {
      & i {
        transform: rotate(180deg);
      }
    }
    & i {
      font-weight: 400;
    }
  }
`;
export const PlanDetailMobile = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 12px;
  & img {
    height: auto;
    width: auto;
    max-width: 30%;
  }
  & .name {
    font-weight: 600;
    font-size: 16px;
    &__wrapper {
      max-width: 60%;
    }
  }
  & .premium {
    font-size: 16px;
    font-weight: bold;
    & span {
      display: inline-block;
    }
    & .price {
      color: var(--secondary-color);
      font-size: 18px;
    }
  }
`;
export const SectionMobile = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const AddonsMobile = styled.div`
  & .addon_name {
    font-size: 14px;
    margin-bottom: 8px;
  }
  & i {
    color: var(--primary-color);
    font-size: 12px;
    font-weight: 400;
  }
`;
export const PlanDetail = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;
  & img {
    height: 60px;
    width: auto;
    margin-bottom: 12px;
  }
  & div {
    font-weight: 700;
    font-color: var(--tertiary-color);
    font-size: 18px;
  }
`;
export const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;

  & h4 {
    font-weight: 700;
    color: var(--dark-grey-1);
    font-size: 16px;
    margin-bottom: 16px;
  }
`;

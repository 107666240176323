import styled from "styled-components";
export const ProposalFormWrapper = styled.div`
  width: 608px;
  display: flex;

  margin: 50px auto;
  flex-direction: column;
  @media (max-width: 1199px) {
    max-width: 500px;
  }
  @media (max-width: 991px) {
    width: 100%;
  }
`;
export const FormCard = styled.form`
  background: #ffffff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  padding: 24px 28px;
  margin-bottom: 16px;
  position: relative;
  padding-bottom: ${(props) => props.padding && "0px"};

  @media (max-width: 991px) {
    border: 0.5px solid #a2adb2;
    padding: 24px 16px;
  }
`;
export const Edit = styled.div`
  color: var(--primary-color);
  font-size: 18px;
  font-weight: 600;
  position: absolute;
  top: 30px;
  right: 28px;
  &:hover {
    text-decoration: underline;
  }
  @media (max-width: 991px) {
    font-size: 14px;
  }
`;
export const FormTitle = styled.div`
  font-weight: 600;
  font-size: 24px;
  color: var(--tertiary-color);
  @media (max-width: 991px) {
    font-size: 18px;
  }
`;
export const ProposalPageWrapper = styled.div`
  display: flex;
  @media (max-width: 991px) {
    flex-direction: column;
    padding: 0 12px;
  }
`;
export const InputWrapper = styled.div`
  margin-bottom: 28px;
`;
export const SummaryDetailsWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0 0 0 20px;
`;

import styled from "styled-components";

export const HeaderWrap = styled.header`
  padding: 10px 0;
  position: relative;
  width: 100%;
  display: ${(props) => props.showOnMobile && "none"};
  @media (max-width: 991px) {
    margin: ${(props) => props.showOnMobile && "0 -26px"};
  }
  @media (max-width: 576px) {
    display: ${(props) => props.showOnMobile && "block"};
    margin: ${(props) => props.showOnMobile && "0 -26px"};
  }
`;
export const NavbarCol = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 991px) {
    justify-content: unset;
    gap: 32px;
    padding: 0 20px;
  }
`;
export const Logo = styled.a`
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 0;
  line-height: 1.5;
  white-space: nowrap;
  @media (max-width: 991px) {
    text-align: center;
  }
`;
export const Nav = styled.nav`
  @media (max-width: 991px) {
    display: none;
  }
`;
export const NavLinks = styled.div`
  font-size: 1.125rem;
  display: inline-flex;
  gap: 2.5rem;
  margin-right: 40px;
  @media (max-width: 991px) {
    flex-direction: column;
  }
`;
export const NavLink = styled.a`
  display: inline-block;
  text-decoration: none;
  color: var(--dark-grey-1);
  &:link,
  &::visited {
    color: var(--dark-grey-1);
  }
  &:hover {
    text-decoration: none;
    color: var(--primary-color);
  }
`;
export const ButtonWrapper = styled.div`
  display: inline-flex;
  justify-content: space-between;
  gap: 24px;
`;
export const NavButton = styled.div`
  display: none;
  @media (max-width: 991px) {
    display: inline-block;
  }
`;

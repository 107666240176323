import React from "react";
import "styled-components/macro";
const Section = ({ title, children }) => {
  return (
    <>
      <div
        css={`
          margin-bottom: 32px;
        `}
      >
        <div
          css={`
            font-size: 16px;
            font-weight: 700;
          `}
        >
          {title}
        </div>
        {children}
      </div>
    </>
  );
};

export default Section;

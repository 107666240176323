import React, { useEffect, useState } from "react";
import Modal from "./../../../../components/Modal/Modal";
import { FormName, FormSubName, Header, Wrapper } from "./NoCarNumber.style";
import Brand from "./components/Brand";
import Model from "./components/Model";
import Fuel from "./components/Fuel";
import Variant from "./components/Variant";
import Registration from "./components/Registration";
import { useSelector } from "react-redux";
import close from "./../../../../assets/images/close.png";
import "styled-components/macro";
import {
  clearNoCarDetails,
  fallBackSelector,
  noNumberSelector,
  setFallbackToLanding,
  setFallbackToNoCar,
} from "../../Landing.slice";
import { useDispatch } from "react-redux";
import { ArrowRightAlt } from "@material-ui/icons";
import Navbar from "../../../../components/Navbar/Navbar";
import {
  Dialog,
  Step,
  StepLabel,
  Stepper,
  useMediaQuery,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { CloseButton } from "../../../../components/Modal/Modal.style";
const useStyles = makeStyles({
  paper: {
    maxHeight: "calc(100% - 40px)",
  },
  paperFullScreen: {
    maxHeight: "100%",
  },
  alternativeLabel: {
    "& .MuiTypography-root": {
      marginTop: "8px !important",
      fontWeight: 600,
    },
  },
  labelContainer: {
    cursor: "pointer",
  },
});
const useStepIconsStyles = makeStyles({
  active: {
    color: "var(--primary-color) !important",
  },
  completed: {
    color: "var(--primary-color) !important",
  },
});
const NoCarNumber = ({ show, setShow, setShowPreviousPolicy }) => {
  const [active, setActive] = useState(0);
  const dispatch = useDispatch();
  const { type, lastActive } = useSelector(fallBackSelector);
  const classes = useStyles();
  const stepIconClasses = useStepIconsStyles();
  const {
    BRAND_ID,
    BRAND_TITLE,
    MODEL_ID,
    MODEL_NAME,
    TYPE_OF_FUEL,
    VARIANT_NAME,
    registration_year,
  } = useSelector(noNumberSelector);
  const onClose = () => {
    setShow(false);
    dispatch(setFallbackToLanding());
    dispatch(clearNoCarDetails());
    setActive(0);
  };
  //This will update the active section on every change
  useEffect(() => {
    if (show) {
      dispatch(setFallbackToNoCar({ lastActive: active }));
    }
  }, [active, show, dispatch]);

  // It will update the last active section if the journey type is without any number.
  useEffect(() => {
    if (type === "NOCAR") {
      setActive(lastActive);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastActive]);
  const fullScreen = useMediaQuery("(max-width:576px)");

  return (
    <>
      <Dialog
        open={show}
        onClose={onClose}
        fullWidth
        maxWidth={"md"}
        fullScreen={fullScreen}
        disableEnforceFocus
        classes={classes}
      >
        <CloseButton
          onClick={() => {
            onClose();
          }}
        >
          <img src={close} alt='close' />
        </CloseButton>
        <Wrapper>
          <Navbar showOnMobile />
          <Header>
            <Stepper
              activeStep={active}
              alternativeLabel
              style={{ padding: 0, width: "100%" }}
            >
              <Step>
                <StepLabel
                  classes={classes}
                  StepIconProps={{
                    classes: stepIconClasses,
                  }}
                  onClick={() => {
                    if (active > 0) setActive(0);
                  }}
                >
                  {BRAND_TITLE || "Brand"}
                </StepLabel>
              </Step>
              <Step>
                <StepLabel
                  StepIconProps={{
                    classes: stepIconClasses,
                  }}
                  classes={classes}
                  onClick={() => {
                    if (active > 1) setActive(1);
                  }}
                >
                  {MODEL_NAME || "Car"}
                </StepLabel>
              </Step>
              <Step>
                <StepLabel
                  StepIconProps={{
                    classes: stepIconClasses,
                  }}
                  classes={classes}
                  onClick={() => {
                    if (active > 2) setActive(2);
                  }}
                >
                  {TYPE_OF_FUEL || "Fuel"}
                </StepLabel>
              </Step>
              <Step>
                <StepLabel
                  StepIconProps={{
                    classes: stepIconClasses,
                  }}
                  classes={classes}
                  onClick={() => {
                    if (active > 3) setActive(3);
                  }}
                >
                  {VARIANT_NAME || "Model"}
                </StepLabel>
              </Step>
              <Step>
                <StepLabel
                  StepIconProps={{
                    classes: stepIconClasses,
                  }}
                  classes={classes}
                  onClick={() => {
                    if (active > 4) setActive(4);
                  }}
                >
                  {registration_year || "Registration"}
                </StepLabel>
              </Step>
            </Stepper>
          </Header>
          {
            <Form
              active={active}
              setActive={setActive}
              setShow={setShow}
              setShowPreviousPolicy={setShowPreviousPolicy}
            ></Form>
          }
        </Wrapper>
      </Dialog>
    </>
  );
};
const Form = ({ active, setActive, setShow, setShowPreviousPolicy }) => {
  if (active === 0) return <Brand active={active} setActive={setActive} />;
  if (active === 1) return <Model active={active} setActive={setActive} />;
  if (active === 2) return <Fuel active={active} setActive={setActive} />;
  if (active === 3) return <Variant active={active} setActive={setActive} />;
  if (active === 4)
    return (
      <Registration
        active={active}
        setActive={setActive}
        onSave={() => {
          setShow(false);
          setShowPreviousPolicy(true);
        }}
      />
    );
  return <></>;
};

export default NoCarNumber;

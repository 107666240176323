import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  activeIndex: 0,
  proposalData: {},
  showInspectionPopup: false,
  inspectionInfo: {},
};
const proposal = createSlice({
  name: "proposal",
  initialState,
  reducers: {
    goNextIndex: (state) => {
      state.activeIndex = state.activeIndex + 1;
    },
    goPrevIndex: (state) => {
      state.activeIndex = state.activeIndex - 1;
    },
    setActiveIndex: (state, { payload }) => {
      state.activeIndex = payload;
    },
    setProposalData: (state, { payload }) => {
      state.proposalData = payload;
    },
    setInspectionInfo: (state, { payload }) => {
      state.inspectionInfo = payload;
      state.showInspectionPopup = true;
    },
    resetProposal: (state, { payload }) => {
      state.proposalData = {};
      state.inspectionInfo = {};
      state.activeIndex = 0;
      state.showInspectionPopup = false;
    },
  },
});

export const {
  goNextIndex,
  goPrevIndex,
  setActiveIndex,
  setProposalData,
  setInspectionInfo,
  resetProposal,
} = proposal.actions;
export default proposal.reducer;

import * as yup from "yup";
const validation = yup.object().shape({
  is_ckyc_no: yup.mixed().required(),
  ckyc_no: yup
    .string()
    .nullable()
    .when("is_ckyc_no", {
      is: "Y",
      then: yup
        .string()
        .required("This field is required")
        .matches(/^[0-9]$/, "Please enter a valid value"),
    }),

  method_of_verification: yup
    .string()
    .nullable()
    .when("is_ckyc_no", {
      is: "N",
      then: yup.string().required("This field is required"),
    }),
  pan_no: yup
    .string()
    .nullable()
    .when("is_ckyc_no", {
      is: "N",
      then: yup
        .string()
        .required("This field is required")
        .matches(
          /^[A-Z]{3}[P]{1}[A-Z]{1}[0-9]{4}[A-Z]{1}$|^$/,
          "Please enter a valid pan no."
        ),
    }),
  address_proof: yup.string().nullable(),
  identity_proof: yup.string().nullable(),
  address_proof_doc: yup.mixed().nullable(),
  identity_proof_doc: yup.mixed().nullable(),
  photo_doc: yup.mixed().nullable(),
});

export default validation;

import React from "react";
import { H1, Wrapper } from "./Heading.style";

const Heading = () => {
  return (
    <>
      <Wrapper>
        <H1>Renew Car Insurance </H1>
        <H1 secondary>Super Easy</H1>
      </Wrapper>
    </>
  );
};

export default Heading;

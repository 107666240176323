import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useEnquiryQuery } from "../../../../../../services/CarServices";

import { CarName } from "../../../../../Landing/components/PreviousPolicy/components/CarDetails/CarDetails.style";
import { noNumberSelector } from "../../../../../Landing/Landing.slice";
import EditDetailsDialog from "../../../EditDetailsDialog/EditDetailsDialog";
import {
  AdditionalDetails,
  DetailsLabel,
  DetailsWrapper,
  EditButton,
  InnerWrapper,
} from "../../CarAndPolicy.style";

const CarDetails = () => {
  const { requestData, responseData } = useEnquiryQuery();
  const [open, setOpen] = useState();
  const onClose = () => {
    setOpen(false);
  };

  return (
    <>
      <DetailsWrapper seperator>
        <InnerWrapper>
          <DetailsLabel
            primary
          >{`${requestData?.vehicle_make} ${requestData?.vehicle_model}`}</DetailsLabel>
          <AdditionalDetails>
            <li>Private Car</li>
            <li>{requestData?.registration_date?.split("-")[0]}</li>
            <li>{requestData?.fuel_type}</li>
            <li>{requestData?.rto_number}</li>
          </AdditionalDetails>
        </InnerWrapper>
        <EditButton onClick={() => setOpen(true)}>Edit</EditButton>
        <EditDetailsDialog open={open} onClose={onClose} />
      </DetailsWrapper>
    </>
  );
};

export default CarDetails;

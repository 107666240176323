import styled from "styled-components";
export const DialogTitle = styled.div`
  display: flex;
  padding: 16px 12px;
  justify-content: space-between;
  align-items: center;
  & div {
    font-weight: 600;
    font-size: 18px;
  }
`;
export const CloseButton = styled.button`
  background: transparent;
  border: none;
  color: var(--dark-grey-2);
  display: flex;
  align-items: center;
  &:focus {
    outline: none;
  }
`;
export const FormWrapper = styled.div`
  margin-top: 20px;
  padding: 0 12px;
`;
export const VehicleDetailWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 40px;
`;
export const VehicleDetail = styled.div``;
export const VehicleName = styled.div`
  font-weight: 600;
  color: var(--tertiary-color);
  font-size: 20px;
`;
export const VehicleNumber = styled.div`
  color: var(--dark-grey-1);
  font-weight: 600;
`;
export const ChangeButton = styled.button`
  background: none;
  border: none;
  font-weight: 600;
  color: var(--primary-color);
  &:hover {
    text-decoration: underline;
  }
`;
export const InputWrapper = styled.div`
  margin-bottom: 24px;
`;
export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 12px;
  margin-bottom: 24px;
  margin-top: 40px;
  & div {
    flex: 1 1;
    text-align: center;
  }
  @media (max-width: 576px) {
    width: 100%;
  }
`;

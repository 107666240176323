import React from "react";
import { useDispatch, useSelector } from "react-redux";
import "styled-components/macro";
import { Button } from "../../../../../../components/Button/Button";
import {
  useBrandQuery,
  useFuelQuery,
  useModelQuery,
  useVariantQuery,
} from "../../../../../../services/CarServices";
import { InputWrapper } from "../../../../../Proposal/ProposalForm.style";
import {
  noNumberSelector,
  setBrandDetails,
  setFallbackToLanding,
  setFuelDetails,
  setModelDetails,
  setVariantDetails,
} from "../../../../Landing.slice";
import AutoCompleteSelect from "./../../../../../../components/AutoCompleteSelect/AutoComplete";
const EditCarDetails = ({ setEdit, setShow }) => {
  const { brandList, isLoading } = useBrandQuery();
  const {
    BRAND_ID,
    BRAND_TITLE,
    MODEL_NAME,
    MODEL_ID,
    TYPE_OF_FUEL,
    VARIANT_NAME,
    VERSION_ID,
  } = useSelector(noNumberSelector);

  const { modelList } = useModelQuery(BRAND_ID);
  const { fuelList } = useFuelQuery(MODEL_ID);
  const { variantList } = useVariantQuery({
    MODEL_ID,
    TYPE_OF_FUEL,
  });
  const { regNo } = useSelector((state) => state.input);
  const dispatch = useDispatch();
  return (
    <div
      css={`
        display: flex;
        flex-direction: column;
        padding: 30px 20px;
      `}
    >
      <div
        css={`
          display: flex;
          width: 100%;
          justify-content: space-between;
          align-items: flex-end;
          margin-bottom: 40px;
          & p {
            margin: 0;
          }
        `}
      >
        <div>
          <p
            css={`
              font-weight: 600;
              color: var(--dark-grey-1);
              font-size: 14px;
            `}
          >
            Edit Your Car Details
          </p>
          <p
            css={`
              font-size: 20px;
              font-weight: 700;
              color: var(--tertiary-color);
            `}
          >
            {regNo}
          </p>
        </div>
        <div
          css={`
            color: var(--secondary-color);
            cursor: pointer;
          `}
          onClick={() => {
            dispatch(setFallbackToLanding());
            setShow(false);
          }}
        >
          Edit
        </div>
      </div>
      <InputWrapper>
        <AutoCompleteSelect
          size='medium'
          options={brandList || []}
          getOptionLabel={(item) => item.BRAND_TITLE || ""}
          label={"Brand"}
          _key={"BRAND_TITLE"}
          selectedValue={{ BRAND_TITLE, BRAND_ID }}
          getOptionSelected={(item, value) => item?.BRAND_ID === value.BRAND_ID}
          handleChange={(val) => {
            dispatch(
              setBrandDetails({
                BRAND_ID: val.BRAND_ID,
                BRAND_TITLE: val.BRAND_TITLE,
              })
            );
          }}
        />
      </InputWrapper>
      <InputWrapper>
        <AutoCompleteSelect
          size='medium'
          options={modelList || []}
          getOptionLabel={(item) => item.MODEL_NAME || ""}
          label={"Model"}
          _key={"MODEL_NAME"}
          selectedValue={{ MODEL_NAME, MODEL_ID }}
          getOptionSelected={(item, value) => item?.MODEL_ID === value.MODEL_ID}
          handleChange={(val) => {
            dispatch(
              setModelDetails({
                MODEL_ID: val.MODEL_ID,
                MODEL_NAME: val.MODEL_NAME,
              })
            );
          }}
        />
      </InputWrapper>
      <InputWrapper>
        <AutoCompleteSelect
          size='medium'
          options={fuelList || []}
          getOptionLabel={(item) => item}
          label={"Fuel"}
          selectedValue={TYPE_OF_FUEL}
          handleChange={(val) => {
            dispatch(setFuelDetails(val));
          }}
        />
      </InputWrapper>
      <InputWrapper>
        <AutoCompleteSelect
          size='medium'
          options={variantList || []}
          getOptionLabel={(item) => item.VARIANT_NAME || ""}
          label={"Variant"}
          _key={"VARIANT_NAME"}
          selectedValue={{ VARIANT_NAME, VERSION_ID }}
          getOptionSelected={(item, value) =>
            item?.VERSION_ID === value.VERSION_ID
          }
          handleChange={(val) => {
            dispatch(
              setVariantDetails({
                VARIANT_NAME: val.VARIANT_NAME,
                VERSION_ID: val.VERSION_ID,
              })
            );
          }}
        />
      </InputWrapper>
      <Button
        primary
        fullWidth
        onClick={() => {
          setEdit(false);
        }}
      >
        Done
      </Button>
    </div>
  );
};

export default EditCarDetails;

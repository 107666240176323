import { useQuery } from "react-query";
import api from "../api/api";
import useUrlQuery from "../hooks/useUrlQuery";
export const useStatusQuery = () => {
  const { get } = useUrlQuery();
  const proposal_id = get("proposal_id");
  const { data, isLoading, isSuccess } = useQuery(
    ["status", proposal_id],
    async () =>
      await api.post("api/GetPolicyDetails", { PROPOSAL_ID: proposal_id })
  );

  return { status: data?.data?.POLICY_DATA[0], isLoading, isSuccess };
};
export const useGetDocument = () => {
  const { isSuccess, status } = useStatusQuery();
  const { get } = useUrlQuery();
  const proposal_id = get("proposal_id");
  const { data, isLoading, refetch } = useQuery(
    ["document"],
    () => api.post("api/GenerateDocument", { PROPOSAL_ID: proposal_id }),
    {
      enabled: isSuccess && !status.POLICY_DOC,
    }
  );
  return { data: data?.data?.response?.POLICY_DOC, isLoading, refetch };
};

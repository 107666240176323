import React, { useEffect, useState } from "react";
import {
  ButtonsWrapper,
  DontKnowNumber,
  Form,
  FormWrapper,
  InputWrapper,
} from "./InputForm.style";
import "styled-components/macro";
import TextInput from "../../../../components/TextInput/TextInput";
import { Button } from "../../../../components/Button/Button";
import NoCarNumber from "../NoCarNumber/NoCarNumber";
import FormHeading from "./components/FormHeading";
import { useDispatch, useSelector } from "react-redux";
import { fallBackSelector, setRegNo } from "../../Landing.slice";
import PreviousPolicy from "../PreviousPolicy/PreviousPolicy";
import { allowRegistrationNumber } from "../../../../utils/onInput";
import { useGetCarDetailsMutation } from "../../../../services/CarServices";

const InputForm = () => {
  const [showNoCarNumber, setShowNoCarNumber] = useState(true);
  const [showPreviousPolicy, setShowPreviousPolicy] = useState(false);
  const { type } = useSelector(fallBackSelector);
  const { getCarDetails } = useGetCarDetailsMutation();
  // const [regNo, setRegNo] = useState("");
  const dispatch = useDispatch();
  const { regNo } = useSelector((state) => state.input);
  useEffect(() => {
    if (type === "NOCAR") {
      setShowNoCarNumber(true);
    }
    if (type === "EXPIRY_FORM") {
      setShowNoCarNumber(false);
      setShowPreviousPolicy(true);
    }
  }, [type]);
  useEffect(() => {
    if (!showNoCarNumber && !showPreviousPolicy) setShowNoCarNumber(true);
  }, [showNoCarNumber, showPreviousPolicy]);
  const onViewQuotes = () => {
    getCarDetails({
      VEHICLE_NUMBER: regNo,
      TYPE: "LITE",
    });
  };
  return (
    <>
      <FormWrapper>
        <Form>
          <FormHeading />
          <InputWrapper>
            {/* <TextInput
              label='Car Number'
              size='large'
              onInput={allowRegistrationNumber}
              onChange={(e) => dispatch(setRegNo(e.target.value))}
              value={regNo}
            /> */}
            <ButtonsWrapper>
              <DontKnowNumber
                onClick={() => {
                  dispatch(setRegNo(""));
                  setShowNoCarNumber(true);
                }}
              >
                Don't know car number ?
              </DontKnowNumber>
              <p
                css={`
                  cursor: pointer;
                `}
                onClick={() => setShowNoCarNumber(true)}
              >
                Got a new car ?
              </p>
            </ButtonsWrapper>
            <Button
              css={`
                @media (max-width: 991px) {
                  margin-top: 24px;
                }
              `}
              primary
              large
              fullWidth
              onClick={onViewQuotes}
            >
              View Quotes
            </Button>
            <p
              css={`
                margin-top: 12px;
                text-align: center;
              `}
            >
              Renew Existing Policy
            </p>
            <Button
              css={`
                display: none;
                @media (max-width: 991px) {
                  margin-top: 24px;
                  display: inline-block;
                }
              `}
              secondary
              large
              fullWidth
              onClick={() => {
                dispatch(setRegNo(""));
                setShowNoCarNumber(true);
              }}
            >
              Don't know car number ?
            </Button>
            <Button
              css={`
                display: none;
                @media (max-width: 991px) {
                  margin-top: 16px;
                  display: inline-block;
                }
              `}
              secondary
              large
              fullWidth
              onClick={() => setShowNoCarNumber(true)}
            >
              Got a new car ?
            </Button>
          </InputWrapper>
        </Form>
      </FormWrapper>
      <NoCarNumber
        show={showNoCarNumber}
        setShow={setShowNoCarNumber}
        setShowPreviousPolicy={setShowPreviousPolicy}
      />
      <PreviousPolicy
        show={showPreviousPolicy}
        setShow={setShowPreviousPolicy}
      ></PreviousPolicy>
    </>
  );
};

export default InputForm;

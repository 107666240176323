import * as yup from "yup";
import { mobileNoExp } from "../../../../../../utils/regularExpressions";

const carOwnerSchema = (product) =>
  yup.object().shape({
    owner_type: yup.string().required(),
    company_name: yup.string().when("owner_type", {
      is: "COMP",
      then: yup.string().required("Company Name is required"),
    }),
    first_name: yup.string().nullable().required("First Name is required"),
    last_name: yup.string().nullable().required("Last Name is required"),

    email: yup
      .string()
      .required("Email is required")
      .email("Please enter a valid email"),
    mobile_no: yup
      .string()
      .required("Mobile no. is required")
      .matches(mobileNoExp, "Please enter a valid mobile no"),
    pan_no: yup.string().when("company_slug", {
      is: "hdfc_ergo",
      then: yup
        .string()
        .required("Pan no. is required")
        .matches(
          /^[A-Z]{3}[P]{1}[A-Z]{1}[0-9]{4}[A-Z]{1}$/,
          "Please enter a valid pan number."
        ),
      otherwise: yup
        .string()
        .nullable()
        .matches(
          /^[A-Z]{3}[P]{1}[A-Z]{1}[0-9]{4}[A-Z]{1}$|^$/,
          "Please enter a valid pan number."
        ),
    }),
    is_pa: yup.boolean(),
    company_slug: yup.string(),
  });
export default carOwnerSchema;

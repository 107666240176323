import { Dialog, Divider, useMediaQuery } from "@material-ui/core";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "styled-components/macro";
// import { Button } from "../../components/Button/Button";
import { useNavigate } from "react-router-dom";
import Check from "../../../../components/Checkbox/Checkbox";
import { Button } from "../../../../components/Button/Button";
import { useInitiateProposalMutation } from "../../../../services/ProposalServices";
import { setShowPaDialog } from "../../QuotesPage.slice";
import {
  CloseButton,
  DialogTitle,
} from "../EditDetailsDialog/EditDetailsDialog.style";
import { Close } from "@material-ui/icons";
const PaDialog = () => {
  const { showPaDialog, selectedQuoteDetails } = useSelector(
    (state) => state.quotes
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const fullScreen = useMediaQuery("(max-width:576px)");
  const [check1, setCheck1] = useState(false);
  const [check2, setCheck2] = useState(false);
  const { initiateProposal, isSubmitting } = useInitiateProposalMutation();
  const onClose = () => {
    dispatch(setShowPaDialog(false));
  };
  return (
    <>
      <Dialog
        open={showPaDialog}
        onClose={onClose}
        fullWidth
        maxWidth={"xs"}
        disableEnforceFocus
        fullScreen={fullScreen}
      >
        <DialogTitle>
          <div> Person Accident Cover is Mandatory as per IRDAI</div>
          <CloseButton onClick={() => onClose()}>
            <Close />
          </CloseButton>
        </DialogTitle>
        <Divider />
        <div
          css={`
            padding: 20px 20px;
          `}
        >
          <div
            css={`
              color: var(--primary-color);
            `}
          >
            You can only opt out if,
          </div>
          <div
            css={`
              display: flex;
              flex-direction: column;
              align-items: center;
            `}
          >
            <div
              css={`
                margin-top: 20px;
              `}
            >
              <Check
                label='The owner does not have driving license'
                checked={check1}
                onClick={() => setCheck1(!check1)}
              />
            </div>
            <div>OR</div>
            <div
              css={`
                margin-top: 10px;
              `}
            >
              <Check
                label='Owner already has PA cover of atleast 15 lacs'
                checked={check2}
                onClick={() => setCheck2(!check2)}
              />
            </div>
          </div>
        </div>
        {(check1 || check2) && (
          <>
            <div
              css={`
                display: flex;
                justify-content: center;
              `}
            >
              <Button
                onClick={() => {
                  initiateProposal(selectedQuoteDetails);
                  dispatch(setShowPaDialog(false));
                }}
                primary
                css={`
                  margin-bottom: 20px;
                  width: 80px;
                  text-align: center;
                  padding: 12px 0;
                `}
              >
                Proceed
              </Button>
            </div>
          </>
        )}
      </Dialog>
    </>
  );
};

export default PaDialog;

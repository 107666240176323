import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  border: 0.5px solid #eaf1f6;
  border-radius: 3px;
  padding: 14px;
`;
export const Heading = styled.div`
  font-size: 18px;
  margin-bottom: 20px;
`;

export const AddonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

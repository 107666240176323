import styled from "styled-components";

export const H1 = styled.h1`
  color: ${(props) =>
    props.secondary ? "var(--secondary-color)" : "var(--tertiary-color)"};
  font-size: 3rem;
  font-weight: bold;
  @media (max-width: 1199px) {
    font-size: 2.2rem;
  }
  @media (max-width: 576px) {
    font-size: 2rem;
    margin-bottom: 8px;
  }
`;
export const Wrapper = styled.div`
  position: absolute;
  left: 118px;
  top: 140px;

  @media (max-width: 1199px) {
    left: 96px;
  }
  @media (max-width: 576px) {
    position: unset;
    top: unset;
    left: unset;
    text-align: center;
  }
`;

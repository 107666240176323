import styled from "styled-components";

export const ModalWrapper = styled.div`
  display: ${(props) => (props.isOpen ? "block" : "none")};
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);
`;
export const ModalContent = styled.div`
  position: absolute;
  width: 65%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  border-radius: 5px;
  box-shadow: rgb(0 0 0 / 50%) 0px 5px 15px;
  @media (max-width: 576px) {
    width: 100%;
    height: 100%;
    overflow-x: hidden;
  }
`;
export const CloseButton = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 1;
  cursor: pointer;
  & img {
    width: 40px;
    height: 40px;
  }
`;

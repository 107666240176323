import React from "react";
import { FormHeading } from "../Contact/Contact.style";
import { AddressLines, AddressWrapper, LogoWrapper } from "./Address.style";
import irdai from "./../../../../assets/images/irdai.png";
const Address = () => {
  return (
    <>
      <FormHeading reduceMargin>Address</FormHeading>
      <AddressWrapper>
        <AddressLines>Registered & Corporate Office:</AddressLines>
        <AddressLines>2nd Floor,Mariyam Tower</AddressLines>
        <AddressLines>K K Road,Ernakulam,Kerala</AddressLines>
        <AddressLines>682 017</AddressLines>
        <AddressLines>CIN No. U66000KL2019PTC058280</AddressLines>
      </AddressWrapper>
      <LogoWrapper>
        <img src={irdai} alt='irdai'></img>
      </LogoWrapper>
      <AddressWrapper>
        <AddressLines>RDAI License No.706</AddressLines>
        <AddressLines>Direct Broker(Life & General)</AddressLines>
        <AddressLines>Valid up to 25/04/2023</AddressLines>
        <AddressLines>Principal Officer - K . H Devaki</AddressLines>
        <AddressLines>+91 0484 4037917</AddressLines>
      </AddressWrapper>
    </>
  );
};

export default Address;

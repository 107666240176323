import { CircularProgress, Divider } from "@material-ui/core";
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import "styled-components/macro";
import useUrlQuery from "../../../../../../hooks/useUrlQuery";
import { useEnquiryQuery } from "../../../../../../services/CarServices";
import { useInitiateProposalMutation } from "../../../../../../services/ProposalServices";
import {
  setSelectedQuotesDetails,
  setShowPaDialog,
  setShowPolicyDetails,
} from "../../../../QuotesPage.slice";
import { BuyNowButton } from "../../../Quotes/components/QuoteCard/QuoteCard.style";
const Details = ({ label, value }) => {
  return (
    <>
      <div
        css={`
          display: flex;
          justify-content: space-between;
          margin-top: 16px;
        `}
      >
        <div
          css={`
            font-size: 14px;
            color: var(--dark-grey-1);
          `}
        >
          {label} :
        </div>
        <div
          css={`
            font-size: 14px;
            font-weight: 600;
          `}
        >
          {value}
        </div>
      </div>
    </>
  );
};
const PlanSummary = ({
  productData,
  premiumData,
  opted,
  additional_premium,
  included,
}) => {
  const { requestData } = useEnquiryQuery();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { get, set } = useUrlQuery();
  const stage = get("stage");
  const quote_id = get("quote_id");
  const { initiateProposal, isSubmitting } = useInitiateProposalMutation();
  const values = [0, 20, 25, 35, 45, 50];
  const applicableNCB = () => {
    const currIndex = values.findIndex(
      (item) => item === requestData?.prev_ncb
    );
    const length = values.length;
    return currIndex < length - 1 ? values[currIndex + 1] : values[length - 1];
  };
  return (
    <>
      <PlanSummaryWrapper>
        <PlanLogo src={productData.LOGO_PATH} />
        <PlanName>{productData.INSURANCE_NAME}</PlanName>
        <Divider />
        <Details
          label={"Cover Value (IDV)"}
          value={"₹" + Number(premiumData.IDV).toLocaleString("en-IN")}
        />
        {requestData?.prev_ncb &&
          productData.POLICY_TYPE !== "TP" &&
          requestData?.policy_expired_before_90_days !== "Y" && (
            <Details label={"Applicable NCB"} value={applicableNCB() + " %"} />
          )}
        <BuyNowButton
          css={`
            width: 100%;
            margin-top: 16px;
            justify-content: center;
            padding: 8px 0;
            @media (max-width: 576px) {
              padding: 12px 0;
            }
          `}
          onClick={() => {
            if (stage !== "PF") {
              navigate(`/quotes?${set("stage", "PF")}`, {
                replace: true,
              });
              dispatch(setShowPolicyDetails(true));
              return;
            }
            const isPaOpted = opted.some((item) => item.slug === "PA_COVER");
            const isOD = productData.POLICY_TYPE === "OD";
            if (isPaOpted || isOD)
              initiateProposal({
                PRODUCT_ID: productData?.PRODUCTID,
                COMPANY_SLUG: productData?.INSURANCE_SLUG,
                IDV: premiumData?.IDV,
                TOTAL_PREMIUM:
                  Number(premiumData?.TOTAL_PREMIUM) + additional_premium,
                ADDONS: [...opted, ...included],
                BASIC_OD: premiumData?.BASIC_OD,
                BASIC_TP: premiumData?.BASIC_TP,
                BIFUEL_OD: premiumData?.ADDONS?.CAN_OPT?.BIFUEL_OD,
                BIFUEL_TP: premiumData?.ADDONS?.CAN_OPT?.BIFUEL_TP,
              });
            else {
              dispatch(setShowPaDialog(true));
              dispatch(
                setSelectedQuotesDetails({
                  PRODUCT_ID: productData?.PRODUCTID,
                  COMPANY_SLUG: productData?.INSURANCE_SLUG,
                  IDV: premiumData?.IDV,
                  TOTAL_PREMIUM:
                    Number(premiumData?.TOTAL_PREMIUM) + additional_premium,
                  ADDONS: [...opted, ...included],
                  BASIC_OD: premiumData?.BASIC_OD,
                  BASIC_TP: premiumData?.BASIC_TP,
                  BIFUEL_OD: premiumData?.ADDONS?.CAN_OPT?.BIFUEL_OD,
                  BIFUEL_TP: premiumData?.ADDONS?.CAN_OPT?.BIFUEL_TP,
                })
              );
            }
          }}
        >
          <div className='label'>Buy Now</div>{" "}
          <div className='price'>
            <>
              {" "}
              ₹{" "}
              {(
                parseInt(premiumData?.TOTAL_PREMIUM) + additional_premium
              ).toLocaleString("en-IN")}
            </>
          </div>
        </BuyNowButton>
      </PlanSummaryWrapper>
    </>
  );
};

export default PlanSummary;
const PlanSummaryWrapper = styled.div`
  width: 26%;
  padding: 24px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.02);
  border-radius: 3px;
  @media (max-width: 576px) {
    width: 100%;
    margin-bottom: 20px;
  }
`;
export const PlanLogo = styled.img`
  width: 140px;
  height: auto;
  margin-bottom: 16px;
`;
export const PlanName = styled.div`
  color: var(--tertiary-color);
  font-weight: 700;
  font-size: 18px;
  line-height: 1;
  margin-bottom: 14px;
`;

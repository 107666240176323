import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@material-ui/core";
import { Add, ArrowForward, Remove } from "@material-ui/icons";
import { makeStyles } from "@mui/styles";
import "styled-components/macro";
import React from "react";
const useAccordionStyles = makeStyles({
  root: {
    marginBottom: "12px",
    boxShadow: "0px 4px 14px #E0F7FF",
    border: "1px solid #DFE2FF !important",
    borderRadius: "4px !important",
    "&::before": {
      backgroundColor: "#fff !important",
      border: "none",
    },
  },
});
const Accordian = ({ expanded, panel, handleChange, title, children }) => {
  const accordionClasses = useAccordionStyles({ expanded });

  return (
    <>
      <Accordion
        expanded={expanded}
        onChange={handleChange(panel)}
        classes={accordionClasses}
      >
        <AccordionSummary
          expandIcon={
            !expanded ? (
              <Add
                style={{
                  fontSize: "40px",
                  color: "var(--primary-color)",
                }}
              />
            ) : (
              <Remove
                style={{
                  fontSize: "40px",
                  color: "var(--primary-color)",
                }}
              />
            )
          }
          aria-controls={`pane${panel}bh-content`}
          id={`pane${panel}bh-header`}
        >
          <div
            css={`
              color: ${expanded
                ? "var(--primary-color)"
                : "var(--dark-grey-1)"};
              font-size: 18px;
              font-weight: 600;
            `}
          >
            <div
              css={`
                display: flex;
                font-size: 22px;
                padding: 0 16px;
              `}
            >
              <div
                css={`
                  width: 40px;
                  background: ${expanded ? "var(--secondary-color)" : "#fff"};
                  border-radius: 50%;
                  color: ${expanded ? "#fff" : "var(--primary-color)"};
                  text-align: center;
                  height: 36px;
                  width: 36px;
                  padding-top: 2px;
                  margin-right: 20px;
                `}
              >
                Q
              </div>
              <div
                css={`
                  color: var(--tertiary-color);
                  font-weight: ${expanded ? 700 : 600};
                `}
              >
                {" "}
                {title}
              </div>
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div
            css={`
              display: flex;
              padding: 0 16px;
            `}
          >
            <div>
              <ArrowForward
                fontSize='30px'
                style={{
                  height: "36px",
                  width: "36px",
                  marginRight: "20px",

                  color: "var(--primary-color)",
                }}
              />
            </div>
            <div> {children}</div>
          </div>
        </AccordionDetails>
      </Accordion>
    </>
  );
};
export default Accordian;

import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useEnquiryQuery } from "../../services/CarServices";
import Addons from "./components/Addons/Addons";
import CarAndPolicy from "./components/CarAndPolicy/CarAndPolicy";
import Filters from "./components/Filters/Filters";
import Quotes from "./components/Quotes/Quotes";
import "styled-components/macro";
import NoQuotes from "./components/NoQuotes/NoQuotes";
import PaDialog from "./components/DialogPA/DialogPA";
const QuotesPage = () => {
  useEnquiryQuery();
  return (
    <Container>
      <CarAndPolicy />
      <Row>
        <Col md={9}>
          <Filters />
          <Quotes />
        </Col>
        <Col
          md={3}
          css={`
            padding: 0;
            @media (max-width: 991px) {
              display: none;
            }
          `}
        >
          <Addons />
        </Col>
      </Row>
      <NoQuotes />
      <PaDialog />
    </Container>
  );
};

export default QuotesPage;

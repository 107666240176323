import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import RadioButton from "../../../../../../components/RadioButton/RadioButton";
import TextInput from "../../../../../../components/TextInput/TextInput";
import { useProposalEnquiry } from "../../../../../../services/ProposalServices";
import {
  lowercase,
  onInputName,
  onInputPhone,
  uppercase,
} from "../../../../../../utils/onInput";
import { yesNoItems } from "../../../../../../utils/YesNoRadioItems";
import {
  InputWrapper,
  SummaryDetailsWrapper,
} from "../../../../ProposalForm.style";
import Summary from "../../../Summary/Summary";
import ClosedForm from "../ClosedForm/ClosedForm";
import carOwnerSchema from "./validationSchema";
const CarOwnerDetails = () => {
  const { proposalData, activeIndex } = useSelector((state) => state.proposal);
  const { proposal, product } = useProposalEnquiry();
  const [formData, setFormData] = useState({
    owner_type: "IND",
    first_name: "",
    last_name: "",
    email: "",
    mobile_no: "",
  });
  const [errors, setErrors] = useState();
  useEffect(() => {
    setFormData({ ...formData, ...proposalData });
  }, [proposalData]);
  useEffect(() => {
    onChange(
      "is_pa",
      proposal?.ADDONS?.some((item) => item.slug === "PA_COVER")
    );
  }, [proposal?.ADDONS]);
  useEffect(() => {
    onChange("company_slug", product?.INSURANCE_SLUG);
  }, [proposal?.ADDONS]);
  useEffect(() => {
    if (formData.owner_type === "COMP") {
      toast.info("For Corporate Policies Premium might subject to change");
    }
  }, [formData.owner_type]);

  const onChange = async (name, data) => {
    setFormData((prev) => ({ ...prev, [name]: data }));
    carOwnerSchema(product)
      .validateAt(name, {
        ...formData,
        [name]: data,
      })
      .then(() => {
        setErrors((prev) => ({ ...prev, [name]: "" }));
      })
      .catch((error) => {
        setErrors((prev) => ({ ...prev, [name]: error.message }));
      });
  };
  const onSubmit = (onSuccess = () => {}) => {
    carOwnerSchema(product)
      .validate(formData, { abortEarly: false })
      .then(() => {
        setErrors({});
        onSuccess({ ...proposalData, ...formData });
      })
      .catch((error) => {
        error.inner.forEach((item) => {
          setErrors((prev) => ({ ...prev, [item.path]: item.message }));
        });
      });
  };
  const SummaryDetails = (
    <>
      <SummaryDetailsWrapper>
        <Summary
          label='Company Owned'
          value={formData.owner_type === "IND" ? "No" : "Yes"}
        />
        {formData.owner_type === "COMP" && (
          <Summary label='Company Name' value={formData.company_name} />
        )}
        <Summary
          label={
            formData.owner_type === "COMP"
              ? "Contact Person's Name"
              : "Full Name"
          }
          value={formData.first_name + " " + formData.last_name}
        />
        <Summary label='Email' value={formData.email} />
        <Summary label='Mobile no.' value={formData.mobile_no} />
        <Summary label='Pan Number' value={formData.pan_no} />
      </SummaryDetailsWrapper>
    </>
  );
  return (
    <>
      <ClosedForm
        title={"1. Car Owner Details"}
        next={
          formData?.owner_type !== "COMP"
            ? "Personal Details"
            : "Communication Details"
        }
        index={0}
        onSubmit={onSubmit}
      >
        {activeIndex > 0 ? (
          SummaryDetails
        ) : (
          <>
            <InputWrapper>
              <RadioButton
                items={[
                  { label: "Yes", value: "COMP" },
                  { label: "No", value: "IND" },
                ]}
                label='Car registered in company name'
                onChange={(val) => {
                  onChange("owner_type", val);
                }}
                defaultValue={formData?.owner_type}
              />
            </InputWrapper>
            {formData.owner_type === "COMP" && (
              <InputWrapper>
                <TextInput
                  size={"medium"}
                  label={"Organisation Name, as in your RC copy"}
                  error={errors?.company_name}
                  onChange={(e) => {
                    onChange("company_name", e.target.value);
                  }}
                  value={formData?.company_name}
                />
              </InputWrapper>
            )}{" "}
            <>
              <InputWrapper>
                <TextInput
                  size={"medium"}
                  label={
                    formData.owner_type === "COMP"
                      ? "Contact Person's First Name"
                      : "First Name, as in your RC copy"
                  }
                  error={errors?.first_name}
                  onInput={onInputName}
                  onChange={(e) => {
                    onChange("first_name", e.target.value);
                  }}
                  inputProps={{
                    readOnly: proposalData?.ckyc,
                  }}
                  value={formData?.first_name}
                />
              </InputWrapper>
              <InputWrapper>
                <TextInput
                  size={"medium"}
                  label={
                    formData.owner_type === "COMP"
                      ? "Contact Person's Last Name"
                      : "Last Name, as in your RC copy"
                  }
                  error={errors?.last_name}
                  onInput={onInputName}
                  value={formData?.last_name}
                  onChange={(e) => {
                    onChange("last_name", e.target.value);
                  }}
                  inputProps={{
                    readOnly: proposalData?.ckyc,
                  }}
                />
              </InputWrapper>
            </>
            <InputWrapper>
              <TextInput
                size={"medium"}
                label={"Email Address"}
                error={errors?.email}
                onInput={lowercase}
                value={formData?.email}
                onChange={(e) => {
                  onChange("email", e.target.value);
                }}
              />
            </InputWrapper>
            <InputWrapper>
              <TextInput
                size={"medium"}
                label={"Mobile Number"}
                inputProps={{ type: "tel" }}
                error={errors?.mobile_no}
                onInput={onInputPhone}
                value={formData?.mobile_no}
                maxLength={10}
                onChange={(e) => {
                  onChange("mobile_no", e.target.value);
                }}
              />
            </InputWrapper>
            <InputWrapper>
              <TextInput
                size={"medium"}
                label={
                  formData.owner_type === "COMP"
                    ? "Company PAN"
                    : product?.INSURANCE_SLUG === "hdfc_ergo"
                    ? "Pan Number"
                    : "Pan Number (Optional)"
                }
                error={errors?.pan_no}
                inputProps={{
                  readOnly: proposalData?.ckyc,
                }}
                onInput={uppercase}
                onChange={(e) => {
                  onChange("pan_no", e.target.value);
                }}
                value={formData?.pan_no}
              />
            </InputWrapper>
          </>
        )}
      </ClosedForm>
    </>
  );
};

export default CarOwnerDetails;

import * as yup from "yup";

const personalDetailsSchema = yup.object().shape({
  gender: yup.string().when("owner_type", {
    is: "IND",
    then: yup.string().required(),
  }),
  owner_type: yup.string(),
  is_married: yup.string().when("owner_type", {
    is: "IND",
    then: yup.string().required(),
  }),
  is_pa: yup.boolean(),
  nominee_name: yup.string().when("owner_type", {
    is: "IND",
    then: yup.string().required("Nominee Name is required"),
  }),
  nominee_age: yup.string().when("owner_type", {
    is: "IND",
    then: yup.string().required("Age is required"),
  }),
  nominee_dob: yup.string().when("owner_type", {
    is: "IND",
    then: yup.string().required("Nominee's Date of Birth is required"),
  }),
  nominee_relation: yup.string().when("owner_type", {
    is: "IND",
    then: yup.string().required("Nominee Relation is required"),
  }),
  dob: yup.string().when("owner_type", {
    is: "IND",
    then: yup.string().required("Date of Birth is required"),
  }),
  company_slug: yup.string().required(),
  occupation: yup.string().when("owner_type", {
    is: "IND",
    then: yup.string().required(),
  }),
});
export default personalDetailsSchema;

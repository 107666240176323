import styled, { keyframes } from "styled-components";
export const Card = styled.div`
  background: #ffffff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  width: 100%;
  display: flex;
  padding: 56px 0 40px;
  justify-content: center;
  margin-top: 24px;
`;
export const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const zoomin = keyframes`
0%{
transform: scale(0) ;

}
50%{
  transform: scale(0.75) ;
}
75%{
  transform: scale(0.25) ;
}
100%{
  transform: scale(1) ;
}
`;
export const StatusIcon = styled.img`
  margin-bottom: 28px;
  height: 73px;
  width: 73px;
  animation: ${zoomin} 1s;
`;

export const MainWrapper = styled.div`
  margin-bottom: 24px;
`;
export const MainText = styled.div`
  font-weight: 600;
  font-size: 24px;
  color: ${(props) => props.primary && "var(--primary-color)"};
  text-align: center;
`;
export const InfoText = styled.p`
  max-width: 868px;
  text-align: center;
  line-height: 29px;
  & span {
    font-size: 18px;
    color: var(--primary-color);
    font-weight: 600;
  }
`;
export const RatingsWrapper = styled.div`
  display: flex;
  max-width: 868px;
  margin-top: 40px;
  gap: 40px;
  justify-content: space-between;
  flex-wrap: wrap;
  @media (max-width: 991px) {
    gap: 20px 0;
    & > div {
      width: 50%;
    }
  }
  @media (max-width: 576px) {
    flex-direction: column;
    & > div {
      width: 100%;
    }
  }
`;
export const RatingsCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const RatingLabel = styled.div`
  text-transform: capitalize;
  font-size: 18px;
  font-weight: 600;

  color: var(--tertiary-color);
`;
export const Download = styled.a`
  background: var(--primary-color);
  padding: 16px 20px;
  margin-top: 12px;
  border-radius: 4px;
  color: #fff;
  font-weight: 600;
  font-size: 18px;
  display: flex;
  align-items: center;
  cursor: pointer;
  &:link,
  &:hover,
  &:visited {
    text-decoration: none;
    color: #fff;
  }
  & i {
    font-size: 24px;
    margin-left: 8px;
  }
`;
export const Retry = styled.div`
  color: var(--primary-color);
  text-decoration: underline;
  cursor: pointer;
`;

import { Divider, useMediaQuery } from "@material-ui/core";
import React, { useState } from "react";
import BackButton from "./components/BackButton/BackButton";
import {
  AddonsMobile,
  PlanCardWrapper,
  PlanDetail,
  PlanDetailMobile,
  Section,
  SectionMobile,
} from "./PlanCard.style";
import "styled-components/macro";
import formatPrice from "./../../../../utils/formatPrice";
import { useProposalEnquiry } from "../../../../services/ProposalServices";
import { Skeleton } from "@mui/material";
import { useSelector } from "react-redux";
const getAddonsPremium = (ADDONS) => {
  if (ADDONS && ADDONS.length)
    return ADDONS.reduce(
      (prev, curr) =>
        prev + Number(curr.premium === "Free" ? "0" : curr.premium),
      0
    );
  return 0;
};
const PlanCard = () => {
  const { isLoading, vehicle, proposal, product } = useProposalEnquiry();
  const [seeMore, setSeeMore] = useState(false);
  const isMobile = useMediaQuery("(max-width:991px)");
  const { proposalData } = useSelector((state) => state.proposal);
  const isPA = proposal?.ADDONS?.some((item) => item.slug === "PA_COVER");
  const PA_COVER = proposal?.ADDONS?.find((item) => item.slug === "PA_COVER");
  const shouldUpdatePremium = isPA && proposalData.owner_type === "COMP";
  return (
    <>
      <PlanCardWrapper
        className={`${isMobile ? (seeMore ? "less" : "more") : ""}`}
      >
        {!isMobile || seeMore ? (
          <>
            <BackButton seeMore={seeMore} />
            <PlanDetail>
              <img src={product?.LOGO_PATH} alt='logo'></img>
              <div>{product?.INSURANCE_NAME}</div>
            </PlanDetail>
          </>
        ) : (
          <>
            <PlanDetailMobile>
              <div className='name__wrapper'>
                <div className='name'>{product?.INSURANCE_NAME}</div>
                <div className='premium'>
                  <span>Premium</span>
                  &nbsp;&nbsp;&nbsp;
                  <span className='price'>
                    {isLoading ? (
                      <Skeleton variant='text' width={"50px"}></Skeleton>
                    ) : (
                      <>
                        ₹{" "}
                        {Number(proposal?.TOTAL_PREMIUM || 0).toLocaleString(
                          "en-IN"
                        )}
                      </>
                    )}
                  </span>
                </div>
              </div>
              <img src={product?.LOGO_PATH} alt='logo'></img>
            </PlanDetailMobile>
            <SectionMobile>
              <AddonsMobile>
                {proposal?.ADDONS &&
                  proposal?.ADDONS.map((item) => (
                    <>
                      <div className='addon_name'>
                        <i class='fa fa-check' aria-hidden='true'></i>{" "}
                        {item.name}
                      </div>
                    </>
                  ))}
              </AddonsMobile>
              <div>
                <BackButton />
              </div>
            </SectionMobile>
          </>
        )}
        {(!isMobile || seeMore) && (
          <>
            <Divider />
            <Section>
              <h4>Vehicle Details</h4>
              <PlanItem
                label={"Manufacturer"}
                value={vehicle?.vehicle_make}
                isLoading={isLoading}
              />
              <PlanItem
                label={"Model Name"}
                value={vehicle?.vehicle_model}
                isLoading={isLoading}
              />
              <PlanItem
                label={"Version"}
                value={vehicle?.vehicle_variant}
                isLoading={isLoading}
              />
              <PlanItem
                label={"IDV"}
                value={`₹ ${Number(proposal?.IDV || 0).toLocaleString(
                  "en-IN"
                )}`}
                isLoading={isLoading}
              />
              <PlanItem
                label={"Previous Policy Expiry Date"}
                value={vehicle?.existing_exp_date}
                isLoading={isLoading}
              />
            </Section>
            <Divider />
            <Section>
              <h4>Premium Breakup</h4>
              <PlanItem
                label={"Basic OD Premium (A)"}
                value={`₹ ${formatPrice(proposal?.BASIC_OD)}`}
              />
              <PlanItem
                label={"Basic TP Premium (B)"}
                value={`₹ ${formatPrice(proposal?.BASIC_TP)}`}
              />
              {/* {proposal?.NCB_DISCOUNT && proposal?.NCB_DISCOUNT !== "0" && (
                <PlanItem
                  label={"NCB Discount (C)"}
                  value={`₹ ${formatPrice(proposal?.NCB_DISCOUNT)}`}
                />
              )} */}
            </Section>
            <Divider />
            <Section>
              <h4>Policy Details</h4>
              <PlanItem
                label={"Coverage Type"}
                value={product?.PRODUCT_NAME}
                isLoading={isLoading}
              />
              {proposal?.BIFUEL_OD &&
                proposal?.BIFUEL_OD !== "0" &&
                product?.INSURANCE_SLUG === "reliance" && (
                  <>
                    <PlanItem
                      label={`${
                        vehicle.fuel_type === "CNG" ? "Internal" : "External"
                      } BI Fuel (OD) ${
                        vehicle.fuel_type === "CNG" ? "(Included)" : ""
                      }`}
                      value={`₹ ${formatPrice(proposal?.BIFUEL_OD / 1.18)}`}
                    />
                  </>
                )}
              {proposal?.BIFUEL_OD &&
                proposal?.BIFUEL_TP !== "0" &&
                product?.INSURANCE_SLUG === "reliance" && (
                  <>
                    {" "}
                    <PlanItem
                      label={`${
                        vehicle.fuel_type === "CNG" ? "Internal" : "External"
                      } BI Fuel (TP) ${
                        vehicle.fuel_type === "CNG" ? "(Included)" : ""
                      }`}
                      value={`₹ ${formatPrice(proposal?.BIFUEL_TP / 1.18)}`}
                    />
                  </>
                )}
              {proposal?.ADDONS &&
                proposal?.ADDONS.map((item) => {
                  if (
                    item.slug === "lpg_cng_si" &&
                    product.INSURANCE_SLUG === "reliance"
                  )
                    return <></>;
                  return (
                    <PlanItem
                      strikeThrough={
                        item.slug === "PA_COVER" && shouldUpdatePremium
                      }
                      label={item.name}
                      value={
                        item.premium === "Free"
                          ? "-"
                          : "₹ " + Number(item.premium).toLocaleString("en-IN")
                      }
                    />
                  );
                })}
            </Section>
            <div
              css={`
                text-align: right;
                font-size: 13px;
              `}
            >
              *Prices are inclusive of all taxes
            </div>
            <Divider />
            <div
              css={`
                display: flex;
                justify-content: space-between;
                padding: 16px 0;
                align-items: center;
              `}
            >
              <div
                css={`
                  font-weight: 700;
                  font-size: 16px;
                  color: var(--tertiary-color);
                `}
              >
                Premium
              </div>
              <div
                css={`
                  font-size: 20px;
                  font-weight: 700;
                  color: var(--secondary-color);
                `}
              >
                {isLoading ? (
                  <Skeleton variant='text' width={"100px"}></Skeleton>
                ) : (
                  <>
                    ₹{" "}
                    {Number(proposal?.TOTAL_PREMIUM || 0).toLocaleString(
                      "en-IN"
                    )}
                  </>
                )}
              </div>
            </div>
          </>
        )}{" "}
        {isMobile && (
          <span
            className={`see ${seeMore ? "less" : "more"}`}
            onClick={() => setSeeMore(!seeMore)}
          >
            See {seeMore ? "Less" : "More"} <i class='fas fa-chevron-down'></i>
          </span>
        )}
      </PlanCardWrapper>
    </>
  );
};

export default PlanCard;
export const PlanItem = ({ label, value, isLoading, strikeThrough }) => {
  return (
    <>
      <div
        css={`
          display: flex;
          justify-content: space-between;
          width: 100%;
          align-items: center;
          &:not(:last-child) {
            margin-bottom: 16px;
          }
        `}
      >
        <div
          css={`
            font-size: 14px;
            color: var(--dark-grey-1);
            text-decoration: ${strikeThrough ? "line-through" : ""};
          `}
        >
          {label} :
        </div>
        <div
          css={`
            font-size: 14px;
            font-weight: 600;
            text-decoration: ${strikeThrough && !isLoading
              ? "line-through"
              : ""};
          `}
        >
          {isLoading ? (
            <Skeleton variant='text' width={"100px"}></Skeleton>
          ) : (
            value
          )}
        </div>
      </div>
    </>
  );
};

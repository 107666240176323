import React from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useFuelQuery } from "../../../../../services/CarServices";
import { noNumberSelector, setFuelDetails } from "../../../Landing.slice";
import {
  BackButton,
  FormLabel,
  FuelBox,
  FuelWrapper,
  Question,
  Text,
} from "../NoCarNumber.style";

const Fuel = ({ active, setActive }) => {
  const { MODEL_ID, TYPE_OF_FUEL } = useSelector(noNumberSelector);
  const { fuelList, isLoading } = useFuelQuery(MODEL_ID);
  const dispatch = useDispatch();
  return (
    <>
      <FormLabel>
        <BackButton
          onClick={() => {
            setActive(active - 1);
          }}
        >
          <i class='fas fa-long-arrow-alt-left'></i>
        </BackButton>
        <Text>Select Your Car </Text>
      </FormLabel>
      <Question>
        What is the <span>fuel</span> type?
      </Question>

      <FuelBox>
        {isLoading ? (
          <div>Loading...</div>
        ) : (
          fuelList.map((item) => (
            <FuelWrapper
              active={item === TYPE_OF_FUEL}
              onClick={() => {
                dispatch(setFuelDetails(item));
                setActive(3);
              }}
            >
              <div>{item}</div>
            </FuelWrapper>
          ))
        )}
      </FuelBox>
    </>
  );
};

export default Fuel;

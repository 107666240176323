import {
  Dialog,
  Divider,
  IconButton,
  makeStyles,
  useMediaQuery,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { useTheme } from "@material-ui/styles";

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AutoCompleteSelect from "../../../../components/AutoCompleteSelect/AutoComplete";
import { Button } from "../../../../components/Button/Button";
import DatePicker from "../../../../components/DatePicker/DatePicker";
import RadioButton from "../../../../components/RadioButton/RadioButton";
import TextInput from "../../../../components/TextInput/TextInput";
// import { useAddonsFromUrl } from "../../../../hooks/addonsFromUrl";
import {
  useEnquiryQuery,
  useFuelQuery,
  useVariantQuery,
} from "../../../../services/CarServices";
import { useFilterMutation } from "../../../../services/QuoteServices";
import { noNumberSelector } from "../../../Landing/Landing.slice";
import {
  ButtonsWrapper,
  ChangeButton,
  CloseButton,
  DialogTitle,
  FormWrapper,
  InputWrapper,
  VehicleDetail,
  VehicleDetailWrapper,
  VehicleName,
  VehicleNumber,
} from "./EditDetailsDialog.style";
const useStyles = makeStyles({
  paper: {
    padding: "0 12px",
  },
});
const EditDetailsDialog = ({ open, onClose }) => {
  const { requestData } = useEnquiryQuery();
  const fullScreen = useMediaQuery("(max-width:576px)");
  const classes = useStyles();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    fuel_type: requestData?.fuel_type,
    vehicle_variant: requestData?.vehicle_variant,
    vehicle_version_id: requestData?.vehicle_version_id,
  });
  useEffect(() => {
    setFormData({
      fuel_type: requestData?.fuel_type,
      vehicle_variant: requestData?.vehicle_variant,
      vehicle_version_id: requestData?.vehicle_version_id,
    });
  }, [requestData]);
  const { fuel_type, vehicle_version_id, vehicle_variant, registration_date } =
    formData;
  const { fuelList } = useFuelQuery(requestData?.model_id);
  const { variantList, isLoading } = useVariantQuery({
    MODEL_ID: requestData?.model_id,
    TYPE_OF_FUEL: fuel_type,
  });
  const {
    update,
    isLoading: isSubmitting,
    isSuccess,
    quote_id,
  } = useFilterMutation();
  // const prevAddon = useAddonsFromUrl();
  useEffect(() => {
    if (isSuccess) {
      // navigate(`/quotes?quote_id=${quote_id}&${prevAddon}`, { replace: true });
      onClose();
    }
  }, [isSuccess, quote_id]);
  const onSubmit = () => {
    update({ ...requestData, ...formData });
  };
  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        fullWidth
        maxWidth={"xs"}
        fullScreen={fullScreen}
        classes={classes}
        disableEnforceFocus
      >
        <DialogTitle>
          <div>Vehicle Details</div>
          <CloseButton onClick={() => onClose()}>
            <Close />
          </CloseButton>
        </DialogTitle>
        <Divider />
        <FormWrapper>
          <VehicleDetailWrapper>
            <VehicleDetail>
              <VehicleName>{`${requestData?.vehicle_make} ${requestData?.vehicle_variant}`}</VehicleName>
              <VehicleNumber>MH-06-AF-0697</VehicleNumber>
            </VehicleDetail>
            <ChangeButton onClick={() => navigate("/")}>Change</ChangeButton>
          </VehicleDetailWrapper>
          <InputWrapper>
            <AutoCompleteSelect
              options={fuelList}
              label='Fuel Type'
              size={"medium"}
              selectedValue={fuel_type}
              handleChange={(val) => {
                setFormData({
                  ...formData,
                  fuel_type: val,
                });
              }}
            />
          </InputWrapper>
          <InputWrapper>
            <AutoCompleteSelect
              label='Variant'
              size={"medium"}
              options={variantList}
              isLoading={isLoading}
              getOptionLabel={(item) => item.VARIANT_NAME}
              selectedValue={{
                VARIANT_NAME: vehicle_variant,
                VERSION_ID: vehicle_version_id,
              }}
              _key={"VERSION_ID"}
              getOptionSelected={(item) =>
                item.VERSION_ID === vehicle_version_id
              }
              handleChange={(val) => {
                setFormData({
                  ...formData,
                  vehicle_variant: val.VARIANT_NAME,
                  vehicle_version_id: val.VERSION_ID,
                });
              }}
            />
          </InputWrapper>

          <ButtonsWrapper>
            <Button secondary onClick={() => onClose()}>
              Cancel
            </Button>
            <Button primary onClick={onSubmit}>
              Apply
            </Button>
          </ButtonsWrapper>
        </FormWrapper>
      </Dialog>
    </>
  );
};

export default EditDetailsDialog;

import React, { useEffect, useState } from "react";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import { styled } from "@material-ui/core";
import styles from "styled-components";
import { makeStyles } from "@material-ui/styles";
const useStyles = makeStyles({
  root: {
    flexDirection: "row",
    alignItems: "center",
    fontSize: "18px",
  },
});
const RadioButton = ({ items, label, onChange, defaultValue, inline, col }) => {
  const [value, setValue] = useState(defaultValue);
  const classes = useStyles();
  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const handleChange = (event) => {
    setValue(event.target.value);
    onChange(event.target.value);
  };
  return (
    <RadioWrapper col={col}>
      <FormControl component='fieldset' classes={inline && classes}>
        <FormLabel
          style={{ marginRight: inline && "12px", marginTop: inline && "-2px" }}
          component='legend'
        >
          {label}
        </FormLabel>
        <RadioGroup
          aria-label='label'
          name='controlled-radio-buttons-group'
          value={value || ""}
          onChange={handleChange}
        >
          {items.map((item, index) => (
            <FormControlLabel
              key={index}
              value={item.value}
              control={<StyledRadio defaultChecked={item.defaultChecked} />}
              label={item.label}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </RadioWrapper>
  );
};

export default RadioButton;
const StyledRadio = styled(Radio)({
  "&:hover": { background: "rgba(0,111,143,0.1)" },
  "&.Mui-checked": {
    color: "var(--primary-color)",
    "&:hover": { background: "rgba(0,111,143,0.1)" },
  },
});
const FormLabel = styles.div`
color:var(--tertiary-color);
`;
const RadioWrapper = styles.div`
& .MuiFormGroup-root{
    flex-direction:${(props) => (props.col ? "column" : "row")};
}
& .MuiFormControlLabel-root{
    &:not(:last-child){
    margin-right:30px;
}
}

`;

import { useLocation } from "react-router-dom";

const useUrlQuery = () => {
  const location = useLocation();
  let params = new URLSearchParams(location.search);
  return {
    get: (name) => params.get(name),
    set: (name, value) => {
      params.set(name, value);
      return params.toString();
    },
    remove: (name) => {
      params.delete(name);
      return params.toString();
    },
  };
};

export default useUrlQuery;

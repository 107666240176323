import { useMutation } from "react-query";
import { toast } from "react-toastify";
import useUrlQuery from "../hooks/useUrlQuery";
import { shareTypes } from "./../components/ShareButton/utils";
import api from "./../api/api";
import { useProposalEnquiry } from "./ProposalServices";
export const useSendQuoteMutation = () => {
  const { get } = useUrlQuery();
  const quote_id = get("quote_id");
  const { mutate } = useMutation(
    (data) => {
      const quoteData = {
        SECTION: "Car",
        QUOTE_ID: quote_id,
        FIRST_NAME: data.first_name,
        LAST_NAME: data.last_name,
        TO: data.type === shareTypes.MAIL ? data.email : data.mobile_no,
        QUOTE_URI: window.location.href,
        QUOTES: data.quotes.map((item) => ({
          LOGO: item?.PRODUCT_DATA?.LOGO_PATH,
          SLUG: item?.PRODUCT_DATA?.INSURANCE_SLUG,
          PREMIUM: item?.PREMIUM_DATA?.PREMIUM_DATA?.TOTAL_PREMIUM,
        })),
      };
      return api.post(
        data.type === shareTypes.MAIL ? "api/EmailQuote" : "api/SMSQuote",
        quoteData
      );
    },
    {
      onSuccess: () => {
        const shareButton = document.querySelectorAll("button.shareButton");
        shareButton[0].classList.add("sent");
        toast.success("Request Submitted Successfully !", {
          position: "top-right",
        });
      },
    }
  );

  return {
    sendQuoteEmail: mutate,
  };
};
export const useSendProposalMutation = () => {
  const { get } = useUrlQuery();
  const proposal_id = get("proposal_id");
  const { proposal, product } = useProposalEnquiry();
  const { mutate } = useMutation(
    (data) => {
      const proposalData = {
        SECTION: "Car",
        PROPOSAL_ID: proposal_id,
        FIRST_NAME: data.first_name,
        LAST_NAME: data.last_name,
        TO: data.type === shareTypes.MAIL ? data.email : data.mobile_no,
        PROPOSAL_URI: window.location.href,
        PREMIUM_AMOUNT: proposal.TOTAL_PREMIUM,
        COMPANY_NAME: product.INSURANCE_NAME,
      };
      return api.post(
        data.type === shareTypes.MAIL ? "api/EmailProposal" : "api/SMSProposal",
        proposalData
      );
    },
    {
      onSuccess: () => {
        const shareButton = document.querySelectorAll("button.shareButton");
        shareButton[0].classList.add("sent");
        toast.success("Request Submitted Successfully !", {
          position: "top-right",
        });
      },
    }
  );

  return {
    sendProposalEmail: mutate,
  };
};
export const useSendPaymentMutation = () => {
  const { get } = useUrlQuery();
  const proposal_id = get("proposal_id");
  const { proposal, product } = useProposalEnquiry();
  const { mutate } = useMutation(
    (data) => {
      const proposalData = {
        SECTION: "Car",
        PROPOSAL_ID: proposal_id,
        FIRST_NAME: data.first_name,
        LAST_NAME: data.last_name,
        TO: data.type === shareTypes.MAIL ? data.email : data.mobile_no,
        PAYMENT_URI: window.location.href,
        PREMIUM_AMOUNT: proposal.TOTAL_PREMIUM,
        COMPANY_NAME: product.INSURANCE_NAME,
      };
      return api.post(
        data.type === shareTypes.MAIL ? "api/EmailPayment" : "api/SMSPayment",
        proposalData
      );
    },
    {
      onSuccess: () => {
        const shareButton = document.querySelectorAll("button.shareButton");
        shareButton[0].classList.add("sent");
        toast.success("Request Submitted Successfully !", {
          position: "top-right",
        });
      },
    }
  );

  return {
    sendPaymentEmail: mutate,
  };
};

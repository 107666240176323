import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Modal from "../../../../components/Modal/Modal";
import CarDetails from "./components/CarDetails/CarDetails";
import { Wrap } from "./PreviousPolicy.style";
import "styled-components/macro";
import ExpiryForm from "./components/ExpiryForm/ExpiryForm";
import { useDispatch } from "react-redux";
import {
  clearNoCarDetails,
  setFallbackToExpiry,
  setFallbackToLanding,
} from "../../Landing.slice";
import close from "./../../../../assets/images/close.png";
import { Dialog, useMediaQuery } from "@material-ui/core";
import "styled-components/macro";
import { CloseButton } from "../../../../components/Modal/Modal.style";
const PreviousPolicy = ({ show, setShow }) => {
  const dispacth = useDispatch();
  const [edit, setEdit] = useState(false);
  const onClose = () => {
    setShow(false);
    dispacth(setFallbackToLanding());
    dispacth(clearNoCarDetails());
  };
  useEffect(() => {
    if (show) {
      dispacth(setFallbackToExpiry());
    }
  }, [show, dispacth]);
  const fullScreen = useMediaQuery("(max-width:576px)");
  return (
    <>
      <Dialog
        open={show}
        onClose={onClose}
        fullWidth
        maxWidth={"md"}
        fullScreen={fullScreen}
        disableEnforceFocus
      >
        <CloseButton
          onClick={() => {
            onClose();
          }}
        >
          <img src={close} alt='close' />
        </CloseButton>
        <Wrap>
          <div
            css={`
              width: 30%;
              @media (max-width: 767px) {
                width: 100%;
              }
            `}
          >
            <CarDetails setShow={setShow} edit={edit} setEdit={setEdit} />
          </div>
          <div
            css={`
              width: 70%;
              padding: 60px 0;
              @media (max-width: 767px) {
                width: 100%;
                padding: 30px 0;
              }

              position: relative;
              &:after {
                display: ${!edit && "none"};
                content: "";
                position: absolute;
                width: 100%;
                height: 100%;
                background: #747989;
                top: 0;
                left: 0;
                opacity: 0.5;
              }
            `}
          >
            <ExpiryForm />
          </div>
        </Wrap>
      </Dialog>
    </>
  );
};

export default PreviousPolicy;

import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  align-items: stretch;
  @media (max-width: 576px) {
    flex-direction: column;
  }
`;
export const MainWrapper = styled.div`
  padding: 44px 0;
  background: #fdfdfd;
  @media (max-width: 576px) {
    /* flex-direction: column; */
    width: 100%;
    padding: 0;
  }
`;
export const Main = styled.div`
  margin: auto;
  width: 70%;
  padding: 24px 32px;
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.02);
  border-radius: 3px;
  height: 360px;
  overflow-y: auto;
  @media (max-width: 576px) {
    width: 100%;
    height: auto;
    box-shadow: unset;
    padding: 16px 0;
  }
  ::-webkit-scrollbar {
    width: 4px;
  }
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #d6dee1;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #d6dee1;
    border-radius: 20px;

    border: 6px solid transparent;
  }
`;

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "../../../../../components/Button/Button";
import { useModelQuery } from "../../../../../services/CarServices";
import { noNumberSelector, setModelDetails } from "../../../Landing.slice";
import {
  BackButton,
  CarWrapper,
  ExpandableCarBox,
  ExpandButtonWrapper,
  FormLabel,
  InputWrapper,
  Question,
  Text,
  TextInput,
} from "../NoCarNumber.style";
import search from "./../../../../../assets/images/search.png";
const Model = ({ active, setActive }) => {
  const [expand, setExpand] = useState(false);
  const { BRAND_ID, BRAND_TITLE, MODEL_ID } = useSelector(noNumberSelector);
  const { modelList, isLoading } = useModelQuery(BRAND_ID);
  const [searchText, setSearchText] = useState("");
  const [filteredList, setFilteredList] = useState(modelList);
  const dispatch = useDispatch();
  useEffect(() => {
    if (searchText)
      setFilteredList(
        modelList.filter((item) =>
          item.MODEL_NAME.toLowerCase().includes(searchText.toLowerCase())
        )
      );
    else setFilteredList(modelList || []);
  }, [searchText, modelList]);

  return (
    <>
      <FormLabel>
        <BackButton
          onClick={() => {
            setActive(active - 1);
          }}
        >
          <i class='fas fa-long-arrow-alt-left'></i>
        </BackButton>
        <Text>Select Your Car </Text>
      </FormLabel>
      <Question>
        Which <span>{BRAND_TITLE}</span> do you drive?
      </Question>
      <InputWrapper>
        <TextInput
          onChange={(e) => setSearchText(e.target.value)}
          id='search'
          placeholder='Search your car model here'
        />

        <label htmlFor='search'>
          <img src={search} alt='search'></img>
        </label>
      </InputWrapper>
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <>
          <ExpandableCarBox expand={expand}>
            {filteredList.length ? (
              filteredList.map((item) => (
                <CarWrapper
                  key={item.MODEL_ID}
                  active={item.MODEL_ID === MODEL_ID}
                  onClick={() => {
                    setActive(2);
                    dispatch(
                      setModelDetails({
                        MODEL_ID: item.MODEL_ID,
                        MODEL_NAME: item.MODEL_NAME,
                      })
                    );
                  }}
                >
                  <div>{item.MODEL_NAME}</div>
                </CarWrapper>
              ))
            ) : (
              <div>No Models Found for your search</div>
            )}
          </ExpandableCarBox>
          {!expand && filteredList.length > 6 && (
            <ExpandButtonWrapper>
              <Button
                onClick={() => setExpand(!expand)}
                secondary
                large
                fullWidth
              >
                View All {filteredList.length} Cars
              </Button>
            </ExpandButtonWrapper>
          )}
        </>
      )}
    </>
  );
};
export default Model;

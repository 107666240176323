import styled from "styled-components";
export const FilterWrapper = styled.div`
  height: 48px;
  border: 0.5px solid #eaf1f6;
  border-radius: 2px;
  margin-bottom: 24px;
  justify-content: space-evenly;
  &:before,
  &:after {
    content: "";
    display: table;
    clear: both;
  }
  @media (max-width: 991px) {
    display: none;
  }
`;
export const MobileFilterWrapper = styled.div`
  display: none;
  @media (max-width: 991px) {
    display: flex;
    background: #ffffff;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05);
    padding: 8px;
    margin-bottom: 20px;
  }
`;
export const MobileFilterContainer = styled.div`
  display: none;
  @media (max-width: 991px) {
    display: flex;
    gap: 12px;
    background: #ffffff;
    padding: 8px;
    width: ${(props) => (props.center ? "45%" : "27%")};
    position: relative;
    &:not(:last-child) {
      &:after {
        position: absolute;
        content: "";
        width: 1px;
        height: 70%;
        background-color: #eaf1f6;
        right: 0;
        top: 15%;
      }
    }
  }
  @media (max-width: 576px) {
    display: flex;
    flex-direction: column;
    gap: 0;
  }
`;
export const FilterContainer = styled.div`
  padding: 0 30px;
  height: 100%;
  float: left;
  width: ${(props) => (props.center ? "40%" : "30%")};
  display: flex;
  align-items: center;
  position: relative;
  gap: 6px;
  &:hover {
    box-shadow: 0px 4px 12px rgba(0, 110, 144, 0.16);
  }
  &:after {
    position: absolute;
    content: "";
    width: 1px;
    height: 70%;
    background-color: #eaf1f6;
    right: 0;
    top: 15%;
  }

  @media (max-width: 1199px) {
    padding: 0 12px;
    font-size: 14px !important;
  }
`;
export const FilterName = styled.div`
  display: inline-block;
  color: var(--dark-grey-1);
`;
export const FilterValue = styled.div`
  display: inline-block;
  color: var(--tertiary-color);
  & .idv_type {
    font-size: 10px;
    color: var(--primary-color);
    font-weight: 800;
  }
`;
export const RadioWrapper = styled.div`
  position: relative;
`;
export const CustomWrapper = styled.div`
  position: absolute;
  bottom: 16px;
  right: 0;
`;
export const CustomValueText = styled.input`
  border: none;
  border-bottom: 1px solid
    ${(props) => (props.error ? "red" : "var(--dark-grey-2)")};
  color: ${(props) => props.error && "red"};
  text-align: center;
  &:focus {
    outline: none;
  }
`;

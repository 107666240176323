import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Check from "../../../../components/Checkbox/Checkbox";
import {
  IDVrangeSelector,
  planTypeSelector,
  removeAddon,
  selectedAddonsSelector,
  setSelectedAddons,
} from "../../QuotesPage.slice";
import {
  FilterContainer,
  FilterName,
  FilterValue,
  FilterWrapper,
  MobileFilterContainer,
  MobileFilterWrapper,
} from "./Filters.style";
import "styled-components/macro";
import PlanTypes from "./components/PlanTypes";
import IDV from "./components/IDV";
import { useEnquiryQuery } from "../../../../services/CarServices";
import { Dialog, DialogTitle, Divider } from "@material-ui/core";

import { Close } from "@material-ui/icons";
import Addons from "../Addons/Addons";
import { CloseButton } from "../../../../components/Modal/Modal.style";
const Filters = () => {
  const addons = useSelector(selectedAddonsSelector);
  const dispatch = useDispatch();
  const [showPlanTypes, setShowPlanTypes] = useState(false);
  const rangeIDV = useSelector(IDVrangeSelector);
  const [showIDV, setShowIDV] = useState(false);
  const plan_types = useSelector(planTypeSelector);
  const { requestData } = useEnquiryQuery();
  const [showAddons, setShowAddons] = useState();

  const getIDV = (val) => {
    if (!val) return null;
    else if (val === rangeIDV.min) {
      return "(Lowest)";
    } else if (val === rangeIDV.max) {
      return "(Highest)";
    } else if (val === Math.ceil((rangeIDV.min + rangeIDV.max) / 2))
      return "(Reco.)";
  };
  return (
    <>
      <FilterWrapper>
        <FilterContainer onClick={() => setShowPlanTypes(true)}>
          <FilterName>Plan Type:</FilterName>{" "}
          <FilterValue>{plan_types?.display_name}</FilterValue>
        </FilterContainer>
        <FilterContainer center onClick={() => setShowIDV(true)}>
          <FilterName>Insured Value (IDV):</FilterName>{" "}
          <FilterValue>
            {(requestData?.IDV && (
              <>
                ₹ {requestData?.IDV.toLocaleString("en-IN")}
                {"  "}
                <>
                  <span className='idv_type'>{getIDV(requestData?.IDV)}</span>
                </>
              </>
            )) ||
              "Select Your IDV"}
          </FilterValue>
        </FilterContainer>
        <FilterContainer>
          <Check
            value={"NILL_DEPT"}
            label={"Zero Depriciation"}
            primary
            checked={addons.includes("NILL_DEPT")}
            onClick={(e) => {
              if (!addons.includes(e.target.value))
                dispatch(setSelectedAddons(e.target.value));
              else dispatch(removeAddon(e.target.value));
            }}
          />
        </FilterContainer>
      </FilterWrapper>
      <MobileFilterWrapper>
        <MobileFilterContainer onClick={() => setShowPlanTypes(true)}>
          <div
            css={`
              color: var(--dark-grey-1);
              font-size: 14px;
              @media (max-width: 576px) {
                font-size: 12px;
              }
            `}
          >
            Plan Type :
          </div>
          <div
            css={`
              font-weight: 600;
              font-size: 14px;
              @media (max-width: 576px) {
                font-size: 12px;
              }
            `}
          >
            {plan_types?.display_name}
          </div>
        </MobileFilterContainer>
        <MobileFilterContainer center onClick={() => setShowIDV(true)}>
          <div
            css={`
              color: var(--dark-grey-1);
              font-size: 14px;
            `}
          >
            Insured Value (IDV) :
          </div>
          <div
            css={`
              font-weight: 600;
              font-size: 14px;
              @media (max-width: 576px) {
                font-size: 12px;
              }
            `}
          >
            {(requestData?.IDV &&
              "₹ " + requestData?.IDV.toLocaleString("en-IN")) ||
              "Select Your IDV"}
          </div>
        </MobileFilterContainer>
        <MobileFilterContainer
          onClick={() => setShowAddons(true)}
          css={`
            justify-content: center;
          `}
        >
          <div
            css={`
              font-weight: 600;
              font-size: 14px;
              @media (max-width: 576px) {
                font-size: 12px;
              }
            `}
          >
            {addons.length} Addons Selected
          </div>
        </MobileFilterContainer>
      </MobileFilterWrapper>
      <PlanTypes open={showPlanTypes} onClose={() => setShowPlanTypes(false)} />
      <IDV open={showIDV} onClose={() => setShowIDV(false)} />
      <MobileAddons show={showAddons} onClose={() => setShowAddons(false)} />
    </>
  );
};
const MobileAddons = ({ show, onClose }) => {
  return (
    <>
      <Dialog
        open={show}
        onClose={onClose}
        fullWidth
        maxWidth={"md"}
        fullScreen
        disableEnforceFocus
      >
        {" "}
        <DialogTitle>
          <div>Select Addons</div>
          <CloseButton onClick={() => onClose()}>
            <Close />
          </CloseButton>
        </DialogTitle>
        <Divider />
        <Addons popup />
      </Dialog>
    </>
  );
};
export default Filters;

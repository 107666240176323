import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Button } from "../../../../../components/Button/Button";
import { useBrandQuery } from "../../../../../services/CarServices";
import { noNumberSelector, setBrandDetails } from "../../../Landing.slice";

import {
  BackButton,
  BrandWrapper,
  ExpandableBox,
  ExpandButtonWrapper,
  FormLabel,
  InputWrapper,
  Question,
  Text,
  TextInput,
} from "../NoCarNumber.style";
import search from "./../../../../../assets/images/search.png";
const Brand = ({ active, setActive }) => {
  const [expand, setExpand] = useState(false);
  const { brandList, isLoading } = useBrandQuery();
  const { BRAND_ID } = useSelector(noNumberSelector);
  const [searchText, setSearchText] = useState("");
  const [filteredList, setFilteredList] = useState(brandList);
  const dispatch = useDispatch();
  useEffect(() => {
    if (searchText)
      setFilteredList(
        brandList.filter((item) =>
          item.BRAND_TITLE.toLowerCase().includes(searchText.toLowerCase())
        )
      );
    else setFilteredList(brandList || []);
  }, [searchText, brandList]);

  return (
    <>
      <FormLabel>
        {" "}
        <BackButton>
          <i className='fas fa-long-arrow-alt-left'></i>
        </BackButton>
        <Text>Select Your Car Brand</Text>
      </FormLabel>
      <Question>
        What is your car's <span>Brand</span>?
      </Question>
      <InputWrapper>
        <TextInput
          id='search'
          placeholder='Search your car brand here'
          onChange={(e) => {
            setSearchText(e.target.value);
          }}
        />
        <label htmlFor='search'>
          <img src={search} alt='search'></img>
        </label>
      </InputWrapper>
      {isLoading ? (
        <div>loading</div>
      ) : (
        <>
          {" "}
          <ExpandableBox expand={expand}>
            {filteredList.length ? (
              filteredList.map((item) => (
                <BrandWrapper
                  active={BRAND_ID === item.BRAND_ID}
                  onClick={() => {
                    setActive(1);
                    dispatch(
                      setBrandDetails({
                        BRAND_ID: item.BRAND_ID,
                        BRAND_TITLE: item.BRAND_TITLE,
                      })
                    );
                  }}
                  title={item.BRAND_TITLE}
                  key={item.BRAND_ID}
                >
                  <img src={item.BRAND_ICON} alt={item.BRAND_TITLE}></img>
                </BrandWrapper>
              ))
            ) : (
              <div>No Cars Found for Your Search</div>
            )}
          </ExpandableBox>
          {!expand && filteredList.length > 8 && (
            <ExpandButtonWrapper>
              <Button onClick={() => setExpand(true)} secondary large fullWidth>
                View All {filteredList?.length} Cars
              </Button>
            </ExpandButtonWrapper>
          )}
        </>
      )}
    </>
  );
};

export default Brand;

import styled from "styled-components";

export const CompanyWrapper = styled.div``;
export const List = styled.ul`
  list-style: none;
  padding: 0;
`;
export const ListItem = styled.li`
  margin-bottom: 12px;
`;
export const ItemLink = styled.a`
  text-transform: capitalize;
  &,
  &:link,
  &:visited {
    text-decoration: none;
    color: #4d4d4d;
    font-size: 14px;
  }
`;

import React from "react";
import Checkbox from "@material-ui/core/Checkbox";
import { FormControlLabel, makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    "&.Mui-checked": {
      color: "var(--primary-color)",
      "&:hover": {
        backgroundColor: "rgba(0,111,143,0.1)",
      },
    },
    "&:hover": {
      backgroundColor: "rgba(0,111,143,0.1)",
    },
  },
});
const Check = ({
  label,
  onClick,
  value,
  primary,
  labelStyle,
  checked,
  checkBoxStyle,
}) => {
  const classes = useStyles();
  return (
    <>
      <FormControlLabel
        label={label}
        classes={classes.label}
        style={{
          color: primary ? "var(--dark-grey-1)" : "var(--tertiary-color)",
          ...labelStyle,
        }}
        control={
          <Checkbox
            value={value}
            classes={classes}
            checked={checked}
            onClick={onClick}
            style={{
              ...checkBoxStyle,
            }}
          />
        }
      />
    </>
  );
};

export default Check;

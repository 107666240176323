import React, { useState } from "react";
import TextInput from "../../../../../../components/TextInput/TextInput";
import { useGaragesQuery } from "../../../../../../services/QuoteServices";
import { Main, MainWrapper } from "../../PolicyDetails.style";
import "styled-components/macro";
import GarageCard from "./components/GarageCard";
const CashlessGarages = ({ productData }) => {
  const [pincode, setPincode] = useState("");
  const { garages, isLoading } = useGaragesQuery({
    pincode,
    company_slug: productData?.INSURANCE_SLUG,
  });
  return (
    <>
      <MainWrapper>
        <Main>
          <div
            css={`
              margin-bottom: 12px;
            `}
          >
            <TextInput
              label={"Pincode"}
              value={pincode}
              onChange={(e) => setPincode(e.target.value)}
              size={"medium"}
            />
          </div>
          {garages && (
            <div
              css={`
                font-weight: 600;
                color: var(--dark-grey-1);
              `}
            >
              {garages.length} garages found in your area
            </div>
          )}
          <div
            css={`
              display: flex;
              flex-direction: column;
            `}
          >
            {garages ? (
              garages.map((item) => (
                <>
                  <GarageCard
                    name={item.WorkshopName}
                    address={item.Address}
                    phone={item.Phone}
                  />
                </>
              ))
            ) : (
              <></>
            )}
          </div>
        </Main>
      </MainWrapper>
    </>
  );
};

export default CashlessGarages;

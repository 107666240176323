import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Check from "../../../../components/Checkbox/Checkbox";
import {
  useFilterMutation,
  useGetAddonsQuery,
} from "../../../../services/QuoteServices";
import {
  removeAddon,
  selectedAddonsSelector,
  setAddOnsWithCover,
  setSelectedAddons,
} from "../../QuotesPage.slice";
import { AddonsWrapper, Heading, Wrapper } from "./Addons.style";
import AutoCompleteSelect from "./../../../../components/AutoCompleteSelect/AutoComplete";
import "styled-components/macro";
import {
  useEnquiryMutation,
  useEnquiryQuery,
} from "../../../../services/CarServices";
import { useNavigate } from "react-router-dom";
import { Button } from "../../../../components/Button/Button";
import useEffectExceptOnMount from "../../../../hooks/useEffectExceptOnMount";
import useUrlQuery from "../../../../hooks/useUrlQuery";
// import { useAddonsFromUrl } from "../../../../hooks/addonsFromUrl";
import dec2bin from "../../../../utils/dec2bin";

const Addons = ({ popup }) => {
  const { get, set } = useUrlQuery();
  const addOns = get("addOns") || 0;

  const current_id = get("quote_id");
  const addons = useSelector(selectedAddonsSelector);
  const { addOnsWithCover } = useSelector((state) => state.quotes);
  const dispatch = useDispatch();
  const { addons_list } = useGetAddonsQuery();

  useEffect(() => {
    if (addons_list)
      dec2bin(addOns).forEach((item, index) => {
        if (
          item === "1" &&
          addons_list[index] &&
          !addons.includes(addons_list[index].ADD_ON_SLUG)
        ) {
          dispatch(setSelectedAddons(addons_list[index].ADD_ON_SLUG));
        }
      });
  }, [addons_list]);

  const onClick = (e, id, is_si) => {
    if (!addons.includes(e.target.value)) {
      dispatch(setSelectedAddons(e.target.value));
      navigate(`/quotes?${set("addOns", Number(addOns) + Number(id))}`, {
        replace: true,
      });
    } else {
      dispatch(removeAddon(e.target.value));
      navigate(`/quotes?${set("addOns", Number(addOns) - Number(id))}`, {
        replace: true,
      });
      if (is_si)
        dispatch(
          setAddOnsWithCover({
            key: e.target.value,
            value: 0,
          })
        );
    }
  };
  const isEveryZero =
    Object.values(addOnsWithCover).every((item) => !item) || false;
  useEffectExceptOnMount(() => {
    if (isEveryZero) {
      update({ ...requestData, ...addOnsWithCover });
    }
  }, [isEveryZero]);
  const navigate = useNavigate();
  const { update, isLoading, isSuccess, quote_id } = useFilterMutation();
  const { requestData } = useEnquiryQuery();
  useEffect(() => {
    if (requestData?.fuel_type === "CNG" && !addons.includes("lpg_cng_si")) {
      dispatch(setSelectedAddons("lpg_cng_si"));
    }
  }, [requestData?.fuel_type]);
  const { min: min_idv } = useSelector((state) => state.quotes.IDV);
  useEffect(() => {
    if (requestData && addons_list) {
      addons_list.forEach((item) => {
        if (item.ALLOWED_SI)
          dispatch(
            setAddOnsWithCover({
              key: item.ADD_ON_SLUG,
              value: requestData[item.ADD_ON_SLUG],
            })
          );
      });
    }
  }, [requestData, addons_list]);
  // const prevAddon = useAddonsFromUrl();
  // useEffect(() => {
  //   if (isSuccess)
  //     navigate(`/quotes?quote_id=${quote_id}&${prevAddon}`, { replace: true });
  // }, [isSuccess, quote_id]);

  return (
    <>
      <Wrapper>
        {!popup && <Heading>Select Addons & Accesories</Heading>}
        <AddonsWrapper>
          {addons_list.map((item, index) => {
            if (!popup && item.ADD_ON_SLUG === "NILL_DEPT") return <></>;
            if (
              item.ADD_ON_SLUG === "lpg_cng_si" &&
              requestData?.fuel_type === "CNG"
            )
              return <></>;
            return (
              <Fragment key={item.ADD_ON_SLUG}>
                {" "}
                <Check
                  label={item.ADD_ON_NAME}
                  value={item.ADD_ON_SLUG}
                  checked={addons.includes(item.ADD_ON_SLUG)}
                  onClick={(e) =>
                    onClick(e, Math.pow(2, index + 1), item.ALLOWED_SI)
                  }
                />
                {item.ALLOWED_SI && addons.includes(item.ADD_ON_SLUG) && (
                  <>
                    <div
                      css={`
                        margin-top: 8px;
                        margin-bottom: 8px;
                      `}
                    >
                      <AutoCompleteSelect
                        options={item.ALLOWED_SI.split(",")}
                        label='Cover'
                        size={"medium"}
                        selectedValue={
                          addOnsWithCover[item.ADD_ON_SLUG] ||
                          (requestData && requestData[item.ADD_ON_SLUG])
                        }
                        customInput={{
                          min: 1,
                          max: min_idv,
                        }}
                        handleChange={(val) => {
                          dispatch(
                            setAddOnsWithCover({
                              key: item.ADD_ON_SLUG,
                              value: val,
                            })
                          );
                          // update({ ...requestData, [item.ADD_ON_SLUG]: val });
                        }}
                      />
                    </div>
                  </>
                )}
              </Fragment>
            );
          })}
          <div
            css={`
              margin-top: 12px;
            `}
          >
            {Object.keys(addOnsWithCover).length && !isEveryZero ? (
              <Button
                onClick={() => {
                  update({ ...requestData, ...addOnsWithCover });
                }}
                primary
              >
                Apply
              </Button>
            ) : (
              <></>
            )}
          </div>
        </AddonsWrapper>
      </Wrapper>
    </>
  );
};

export default Addons;

import React from "react";
import { FormHeading } from "../Contact/Contact.style";
import { SocialItem, SocialList } from "./Social.style";
import twitter from "./../../../../assets/images/twitter.png";
import facebook from "./../../../../assets/images/facebook.png";
import linkedin from "./../../../../assets/images/linkedin.png";
import instagram from "./../../../../assets/images/instagram.png";
const Social = () => {
  return (
    <>
      <FormHeading>Connect With Us</FormHeading>
      <SocialList>
        <SocialItem>
          <img src={twitter} alt='twitter' />
        </SocialItem>
        <SocialItem>
          <img src={facebook} alt='facebook' />
        </SocialItem>
        <SocialItem>
          <img src={instagram} alt='instagram' />
        </SocialItem>
        <SocialItem>
          <img src={linkedin} alt='linkedin' />
        </SocialItem>
      </SocialList>
    </>
  );
};

export default Social;

import React, { useState } from "react";
import { CardWrapper, SectionTitle } from "./InfoSection.style";
import "styled-components/macro";
import Card from "./components/Card";
import claims from "./../../../../assets/images/claims.svg";
import quick from "./../../../../assets/images/quick.svg";
import lowPremium from "./../../../../assets/images/low-premium.svg";
import accidents from "./../../../../assets/images/accidents.svg";
import theft from "./../../../../assets/images/theft.svg";
import thirdparty from "./../../../../assets/images/thirdparty.svg";
import fire from "./../../../../assets/images/fire.svg";
import personalaccident from "./../../../../assets/images/personalaccident.svg";
import naturaldisaster from "./../../../../assets/images/naturaldisaster.svg";
import step1 from "./../../../../assets/images/step1.svg";
import step2 from "./../../../../assets/images/step2.svg";
import step3 from "./../../../../assets/images/step3.svg";
import Accordian from "./components/Accordian";
const InfoSection = () => {
  const [active, setActive] = useState(0);
  const [panel, setPanel] = useState(0);
  const handleChangeAcc = (panel) => (event, isExpanded) => {
    setPanel(isExpanded ? panel : false);
  };
  return (
    <>
      <div
        css={`
          margin-top: 120px;
          margin-bottom: 100px;
        `}
      >
        <SectionTitle>
          Why do all prefer{" "}
          <span className='primary'>
            V<span className='secondary'>K</span>over !
          </span>
        </SectionTitle>
        <CardWrapper>
          <Card
            logo={claims}
            title='Hassle-Free Claims'
            description={
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Diam scelerisque vulputate facilisi parturient."
            }
            width='280px'
            left='-16px'
          />
          <Card
            logo={quick}
            title='Superquick and Easy'
            description={
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Diam scelerisque vulputate facilisi parturient."
            }
            width='280px'
            left='16px'
          />
          <Card
            logo={lowPremium}
            title='Incredibly Low Premiums'
            description={
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Diam scelerisque vulputate facilisi parturient."
            }
            width='280px'
          />
        </CardWrapper>
      </div>
      <div
        css={`
          margin-top: 120px;
          margin-bottom: 100px;
        `}
      >
        <SectionTitle center>
          What is{" "}
          <span className='primary'>
            V<span className='secondary'>K</span>overed In Our Insurance ?
          </span>
        </SectionTitle>
        <CardWrapper>
          <CoveredCard logo={accidents} title='Accidents' />
          <CoveredCard logo={theft} title='Theft' />
          <CoveredCard logo={thirdparty} title='Third Party Loss' />
          <CoveredCard logo={fire} title='Fire' />
          <CoveredCard logo={personalaccident} title='Personal Accident' />
          <CoveredCard logo={naturaldisaster} title='Natural Disaster' />
        </CardWrapper>
      </div>
      <div
        css={`
          margin-top: 60px;
          margin-bottom: 100px;
        `}
      >
        <SectionTitle center>How To</SectionTitle>
        <div
          css={`
            display: flex;
            margin: 0 auto;
            justify-content: center;
            margin-bottom: 50px;
          `}
        >
          {["Buy Policy", "Claim Insurance", "Renew Policy"].map(
            (item, idx) => (
              <div
                onClick={() => {
                  setActive(idx);
                }}
                css={`
                  font-size: 20px;
                  margin-right: ${idx === 2 ? "0px" : "40px"};
                  font-weight: ${active === idx && 700};
                  border-bottom: ${active === idx &&
                  "3px solid var(--primary-color)"};
                  padding: 2px 0;
                  color: ${active === idx
                    ? "var(--primary-color)"
                    : "var(--dark-grey-1)"};
                  @media (max-width: 576px) {
                    font-size: 16px;
                    margin-right: ${idx === 2 ? "0px" : "20px"};
                  }
                `}
              >
                {item}
              </div>
            )
          )}{" "}
        </div>
        <CardWrapper style={{ padding: "0 60px" }}>
          <Card
            logo={step1}
            title='Step 1'
            description={
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Diam scelerisque vulputate facilisi parturient."
            }
            width='260px'
            height='100px'
          />
          <Card
            logo={step2}
            title='Step 2'
            description={
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Diam scelerisque vulputate facilisi parturient."
            }
            width='260px'
            height='100px'
          />
          <Card
            logo={step3}
            title='Step 3'
            description={
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Diam scelerisque vulputate facilisi parturient."
            }
            width='260px'
            height='100px'
          />
        </CardWrapper>
      </div>
      <div
        css={`
          margin-top: 120px;
          margin-bottom: 100px;
        `}
      >
        <SectionTitle>Frequently Asked Questions</SectionTitle>
        <Accordian
          panel={0}
          expanded={panel === 0}
          handleChange={handleChangeAcc}
          title={"Lorem ipsum dolor sit amet, consectetur adipiscing elit ?"}
        >
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dignissim
          viverra cras porta erat. Integer pellentesque eget proin semper
          aliquet aliquam ultricies neque vestibulum. Eget consectetur mauris
          sed venenatis tincidunt vel ultricies. Id tellus, volutpat eget sociis
          volutpat, egestas euismod purus
        </Accordian>
      </div>
    </>
  );
};

export default InfoSection;

const CoveredCard = ({ logo, title }) => {
  return (
    <div
      css={`
        width: calc(33% - 60px);
        display: flex;
        flex-direction: column;
        align-items: center;
        border: 1px solid #ddf5fe;
        box-shadow: 0px 14px 32px rgba(0, 0, 0, 0.04);
        padding: 20px 0;
        margin-bottom: 60px;
        @media (max-width: 991px) {
          width: calc(50% - 10px);
        }
        @media (max-width: 576px) {
          width: 100%;
          max-width: 400px;
        }
      `}
    >
      <img
        src={logo}
        css={`
          height: 100px;
          max-width: 160px;
          margin-bottom: 16px;
        `}
        alt='covered'
      />
      <div
        css={`
          font-size: 24px;
          font-weight: 600;
        `}
      >
        {title}
      </div>
    </div>
  );
};

import React from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useVariantQuery } from "../../../../../services/CarServices";
import { noNumberSelector, setVariantDetails } from "../../../Landing.slice";

import {
  BackButton,
  FormLabel,
  ModelBox,
  ModelWrapper,
  Question,
  Text,
} from "../NoCarNumber.style";

const Variant = ({ active, setActive }) => {
  const { MODEL_ID, TYPE_OF_FUEL, VARIANT_NAME } =
    useSelector(noNumberSelector);
  const { variantList, isLoading } = useVariantQuery({
    MODEL_ID,
    TYPE_OF_FUEL,
  });
  const dispatch = useDispatch();
  return (
    <div>
      <FormLabel>
        <BackButton
          onClick={() => {
            setActive(active - 1);
          }}
        >
          <i class='fas fa-long-arrow-alt-left'></i>
        </BackButton>
        <Text>Select Your Car </Text>
      </FormLabel>
      <Question>
        Which Car <span>Model</span> You Drive?
      </Question>

      <ModelBox expand>
        {isLoading ? (
          <div>Loading...</div>
        ) : (
          variantList.map((item) => (
            <ModelWrapper
              active={item.VARIANT_NAME === VARIANT_NAME}
              onClick={() => {
                dispatch(
                  setVariantDetails({
                    VARIANT_NAME: item.VARIANT_NAME,
                    VERSION_ID: item.VERSION_ID,
                  })
                );
                setActive(4);
              }}
            >
              <div>{item.VARIANT_NAME}</div>
            </ModelWrapper>
          ))
        )}
      </ModelBox>
    </div>
  );
};

export default Variant;

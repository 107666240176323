import React from "react";
import { Main, MainWrapper } from "../../PolicyDetails.style";

const PlanDetails = () => {
  return (
    <>
      <MainWrapper>
        <Main></Main>
      </MainWrapper>
    </>
  );
};

export default PlanDetails;

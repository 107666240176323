import { useState } from "react";
import { useSelector } from "react-redux";
import AutoComplete from "../../../../../../components/AutoCompleteSelect/AutoComplete";
import RadioButton from "../../../../../../components/RadioButton/RadioButton";
import TextInput from "../../../../../../components/TextInput/TextInput";
import { useFinalSubmitMutation } from "../../../../../../services/ProposalServices";
import { onInputPhone, uppercase } from "../../../../../../utils/onInput";
import { yesNoItems } from "../../../../../../utils/YesNoRadioItems";
import { InputWrapper } from "../../../../ProposalForm.style";
import ClosedForm from "../ClosedForm/ClosedForm";
import validation from "./ckycDetailsValidation";

const CKYCDetails = () => {
  const { proposalData, activeIndex } = useSelector((state) => state.proposal);
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    pan_no: proposalData?.pan_no,
  });
  const {
    finalSubmit,
    data,
    isLoading: isProposalSubmitting,
  } = useFinalSubmitMutation();
  const onChange = async (name, data) => {
    setFormData((prev) => ({ ...prev, [name]: data }));
    validation
      .validateAt(name, {
        ...formData,
        [name]: data,
      })
      .then(() => {
        setErrors((prev) => ({ ...prev, [name]: "" }));
      })
      .catch((error) => {
        setErrors((prev) => ({ ...prev, [name]: error.message }));
      });
  };
  const onSubmit = (onSuccess = () => {}) => {
    validation
      .validate(formData, { abortEarly: false })
      .then(() => {
        setErrors({});
        onSuccess({ ...proposalData, ...formData }, { isFormData: true });
      })
      .catch((error) => {
        error?.inner?.forEach((item) => {
          setErrors((prev) => ({ ...prev, [item.path]: item.message }));
        });
      });
  };
  return (
    <>
      <ClosedForm
        title={"CKYC Details"}
        index={4}
        next={"Final Submit"}
        onSubmit={onSubmit}
        finalSubmit={finalSubmit}
        isFormData
      >
        <InputWrapper>
          <RadioButton
            items={yesNoItems}
            label='Do you have CKYC no. ?'
            onChange={(val) => {
              onChange("is_ckyc_no", val);
            }}
            defaultValue={formData?.is_ckyc_no}
          />
        </InputWrapper>

        {formData?.is_ckyc_no === "Y" && (
          <>
            <InputWrapper>
              <TextInput
                size={"medium"}
                label={"CKYC Number"}
                error={errors?.ckyc_no}
                onInput={onInputPhone}
                maxLength={14}
                onChange={(e) => {
                  onChange("ckyc_no", e.target.value);
                }}
                value={formData?.ckyc_no}
              />
            </InputWrapper>
          </>
        )}
        {formData?.is_ckyc_no === "N" && (
          <>
            <InputWrapper>
              <AutoComplete
                size={"medium"}
                label={"Method of Verification"}
                error={errors?.method_of_verification}
                handleChange={(val) => {
                  onChange("method_of_verification", val.OPTION_KEY);
                }}
                _key={"OPTION_VAL"}
                options={verification_methods}
                value={formData?.method_of_verification}
                selectedValue={
                  formData?.method_of_verification && {
                    OPTION_KEY: formData?.method_of_verification,
                    OPTION_VAL:
                      formData?.method_of_verification &&
                      verification_methods?.find(
                        (item) =>
                          item.OPTION_KEY === formData?.method_of_verification
                      )?.OPTION_VAL,
                  }
                }
                getOptionLabel={(item) => item.OPTION_VAL || ""}
                getOptionSelected={(item, val) =>
                  item.OPTION_KEY === val.OPTION_KEY
                }
              />
            </InputWrapper>
            {formData.method_of_verification === "pan" && (
              <>
                <InputWrapper>
                  <TextInput
                    size={"medium"}
                    label={"Pan Number "}
                    error={errors?.pan_no}
                    onInput={uppercase}
                    onChange={(e) => {
                      onChange("pan_no", e.target.value);
                    }}
                    maxLength={10}
                    value={formData?.pan_no}
                  />
                </InputWrapper>
              </>
            )}
            {formData.method_of_verification === "doc" && (
              <>
                <InputWrapper>
                  <AutoComplete
                    size={"medium"}
                    label={"Address Proof"}
                    error={errors?.address_proof}
                    handleChange={(val) => {
                      onChange("address_proof", val.OPTION_KEY);
                    }}
                    options={documents}
                    value={formData?.address_proof}
                    _key={"OPTION_VAL"}
                    selectedValue={
                      formData?.address_proof && {
                        OPTION_KEY: formData?.address_proof,
                        OPTION_VAL:
                          formData?.address_proof &&
                          documents?.find(
                            (item) =>
                              item.OPTION_KEY === formData?.address_proof
                          )?.OPTION_VAL,
                      }
                    }
                    getOptionLabel={(item) => item.OPTION_VAL || ""}
                    getOptionSelected={(item, val) =>
                      item.OPTION_KEY === val.OPTION_KEY
                    }
                  />
                </InputWrapper>
                <InputWrapper>
                  <TextInput
                    size={"medium"}
                    type='file'
                    label={"Address Proof Doc "}
                    error={errors?.address_proof_doc}
                    onChange={(e) => {
                      onChange("address_proof_doc", e.target.files[0]);
                    }}
                  />
                </InputWrapper>
                <InputWrapper>
                  <AutoComplete
                    size={"medium"}
                    label={"Identity Proof"}
                    _key={"OPTION_VAL"}
                    options={documents}
                    error={errors?.identity_proof}
                    handleChange={(val) => {
                      onChange("identity_proof", val.OPTION_KEY);
                    }}
                    value={formData?.identity_proof}
                    selectedValue={
                      formData?.identity_proof && {
                        OPTION_KEY: formData?.identity_proof,
                        OPTION_VAL:
                          formData?.identity_proof &&
                          documents?.find(
                            (item) =>
                              item.OPTION_KEY === formData?.identity_proof
                          )?.OPTION_VAL,
                      }
                    }
                    getOptionLabel={(item) => item.OPTION_VAL || ""}
                    getOptionSelected={(item, val) =>
                      item.OPTION_KEY === val.OPTION_KEY
                    }
                  />
                </InputWrapper>
                <InputWrapper>
                  <TextInput
                    size={"medium"}
                    type='file'
                    label={"Identity Proof Doc "}
                    error={errors?.identity_proof_doc}
                    onChange={(e) => {
                      onChange("identity_proof_doc", e.target.files[0]);
                    }}
                  />
                </InputWrapper>
              </>
            )}
            <InputWrapper>
              <TextInput
                size={"medium"}
                type='file'
                label={"Photo "}
                error={errors?.photo_doc}
                onChange={(e) => {
                  onChange("photo_doc", e.target.files[0]);
                }}
              />
            </InputWrapper>
          </>
        )}
      </ClosedForm>
    </>
  );
};

export default CKYCDetails;

const verification_methods = [
  {
    OPTION_KEY: "pan",
    OPTION_VAL: "PAN Number",
  },
  {
    OPTION_KEY: "doc",
    OPTION_VAL: "Physical Documents",
  },
];

const documents = [
  {
    OPTION_KEY: "D20",
    OPTION_VAL: "CGHS ECHS CARD",
  },
  {
    OPTION_KEY: "D21",
    OPTION_VAL: "PHOTO CREDIT CARD",
  },
  {
    OPTION_KEY: "D22",
    OPTION_VAL: "IT PROOF",
  },
  {
    OPTION_KEY: "D23",
    OPTION_VAL: "PHOTO PENSIONER CARD",
  },
  {
    OPTION_KEY: "D24",
    OPTION_VAL: "BANK STATEMENT",
  },
  {
    OPTION_KEY: "D25",
    OPTION_VAL: "AADHAAR IMAGE",
  },
  {
    OPTION_KEY: "D26",
    OPTION_VAL: "DRIVING LICENSE IMAGE",
  },
  {
    OPTION_KEY: "D27",
    OPTION_VAL: "VOTER ID IMAGE",
  },
  {
    OPTION_KEY: "D28",
    OPTION_VAL: "PASSPORT IMAGE",
  },
];

import React, { useEffect, useState } from "react";
import ReplayIcon from "@material-ui/icons/Replay";
import { Alert } from "@mui/material";
import "styled-components/macro";
import { useGetQuotesQuery } from "../../../../services/QuoteServices";
import { useDispatch } from "react-redux";
import { removeNotGenerated } from "../../QuotesPage.slice";
const AlertComp = ({ logo, message, endPoint }) => {
  const [clicked, setClicked] = useState(false);
  const { refetch } = useGetQuotesQuery(endPoint);
  const dispatch = useDispatch();
  useEffect(() => {
    if (clicked) {
      setTimeout(() => {
        refetch();
        dispatch(removeNotGenerated(endPoint));
        setClicked(false);
      }, 300);
    }
  }, [clicked]);
  return (
    <>
      <Alert
        severity='error'
        style={{
          width: "100%",
          alignItems: "center",
        }}
      >
        <div className='message-quotes'>
          <div className='image-wrapper'>
            <img src={logo} alt='' />
          </div>
          <div>{message}</div>
          {message === "Service unreachable" && (
            <div
              css={`
                margin-left: 60px;
                cursor: pointer;
                display: flex;
                transform: ${clicked ? "rotateZ(360deg)" : "rotateZ(0deg)"};
                transition: transform 0.3s ease-in-out;
              `}
              onClick={() => setClicked(true)}
            >
              <ReplayIcon />
            </div>
          )}
        </div>
      </Alert>
    </>
  );
};

export default AlertComp;

import React from "react";
import { Col, Row } from "react-bootstrap";
import { CarAndPolicyWrapper } from "./CarAndPolicy.style";
import CarDetails from "./componets/CarDetails/CarDetails";
import PolicyDetails from "./componets/PolicyDetails/PolicyDetails";
import "styled-components/macro";
const CarAndPolicy = () => {
  return (
    <>
      <CarAndPolicyWrapper>
        <Row>
          <Col
            md={5}
            css={`
              @media (max-width: 576px) {
                margin-bottom: 20px;
              }
            `}
          >
            <CarDetails />
          </Col>
          <Col md={7}>
            <PolicyDetails />
          </Col>
        </Row>
      </CarAndPolicyWrapper>
    </>
  );
};

export default CarAndPolicy;

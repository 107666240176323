import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useEnquiryQuery } from "../../../../services/CarServices";
import { sortQuotes } from "../../QuotesPage.slice";
import QuoteCard from "./components/QuoteCard/QuoteCard";
import { QuotesWrapper } from "./Quotes.style";

const Quotes = () => {
  const { responseData } = useEnquiryQuery();
  const { selected_addons } = useSelector((state) => state.quotes);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(sortQuotes());
  }, [selected_addons]);
  return (
    <>
      <QuotesWrapper>
        {responseData?.quote_end_points?.map((end_point) => (
          <QuoteCard endPoint={end_point}></QuoteCard>
        ))}
      </QuotesWrapper>
    </>
  );
};

export default Quotes;

import React from "react";
import { Container } from "react-bootstrap";
import InputForm from "./components/InputForm/InputForm";
import Heading from "./components/Heading/Heading";
import { Hero } from "./Landing.style";
import Loader from "../../components/Loader/Loader";
import InfoSection from "./components/InfoSection/InfoSection";

const Landing = () => {
  return (
    <>
      <Container>
        <Hero>
          <Heading />
          <InputForm />
        </Hero>
        <InfoSection />
      </Container>
    </>
  );
};

export default Landing;

import styled from "styled-components";

export const Button = styled.div`
  cursor: pointer;
  padding: ${(props) => {
    if (props.fullWidth) {
      return "14px 0";
    } else if (props.large) {
      return "14px 64px";
    } else return "12px 32px";
  }};
  text-align: center;
  background-color: ${(props) => {
    if (props.primary) {
      return "var(--primary-color)";
    } else if (props.secondary) {
      return "#fff";
    }
  }};
  color: ${(props) => {
    if (props.primary) {
      return "#fff";
    } else if (props.secondary) {
      return "var(--primary-color)";
    }
  }};
  width: ${(props) => props.fullWidth && "100%"};
  text-align: ${(props) => props.fullWidth && "center"};
  border: ${(props) => props.secondary && "1px solid var(--primary-color)"};
  border-radius: 1px;
  font-weight: bold;
  font-size: ${(props) => (props.large ? "1.25rem" : "1rem")};
  line-height: 18px;
  display: block;
  @media (max-width: 576px) {
    padding: ${(props) => {
      if (props.fullWidth) {
        return "14px 0";
      } else if (props.large) {
        return "14px 64px";
      } else return " 6px";
    }};
    font-size: 12px;
  }
`;

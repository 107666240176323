import React from "react";
import "styled-components/macro";

const Card = ({ logo, width = "280px", title, description, left, height }) => {
  return (
    <>
      <div
        css={`
          width: ${width};
          display: flex;
          flex-direction: column;
          align-items: center;

          @media (max-width: 576px) {
            width: 100%;
            max-width: 400px;
            margin-bottom: 24px;
          }
        `}
      >
        <img
          css={`
            width: auto;
            height: ${height || "140px"};
            margin-bottom: 20px;
            position: relative;
            left: ${left};
            @media (max-width: 576px) {
              height: 100px;
              margin-bottom: 12px;
            }
          `}
          src={logo}
          alt='logo'
        />
        <div
          css={`
            font-size: 20px;
            font-weight: 800;
            margin-bottom: 16px;
            @media (max-width: 576px) {
              font-size: 18px;
              margin-bottom: 12px;
            }
          `}
        >
          {title}
        </div>
        <div
          css={`
            text-align: center;
          `}
        >
          {description}
        </div>
      </div>
    </>
  );
};
export default Card;

import styled from "styled-components";

export const LogoWrapper = styled.div`
  height: 48px;
  width: auto;
  margin-bottom: 20px;
  & img {
    height: inherit;
    width: auto;
  }
`;
export const ContactWrapper = styled.div`
  padding: 0 20px;
  @media (max-width: 576px) {
    padding: 0;
  }
`;
export const FormWrapper = styled.form`
  width: 100%;
  height: auto;
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(212, 236, 243, 0.59);
  border-radius: 4px;
  padding: 24px 24px 40px;
`;
export const FormHeading = styled.h3`
  color: var(--primary-color);
  margin-bottom: ${(props) => (props.reduceMargin ? "20px" : "30px")};
  font-weight: 600;
`;
export const TextInput = styled.input`
  width: 100%;
  border: none;
  box-shadow: 0px 1px 7px rgba(71, 93, 101, 0.1);
  border-radius: 2px;
  padding: 14px 18px;
  margin-bottom: 24px;

  &:focus {
    border: none;
    outline: none;
    box-shadow: 0px 4px 10px rgba(71, 93, 101, 0.1);
  }
`;

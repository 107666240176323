import styled from "styled-components";

export const CarAndPolicyWrapper = styled.div`
  width: 100%;
  height: 90px;
  background: #ffffff;
  box-shadow: 0px 1px 11px rgba(0, 0, 0, 0.07);
  padding: 20px 20px;
  margin-bottom: 40px;
  @media (max-width: 991px) {
    height: auto;
  }
  @media (max-width: 576px) {
    height: auto;
    padding: 20px 0;
    box-shadow: none;
    margin-bottom: 20px;
  }
`;
export const DetailsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 12px;
  border-right: ${(props) => props.seperator && "1px solid #eaf1f6"};
  @media (max-width: 991px) {
    margin-bottom: 20px;
  }
  @media (max-width: 576px) {
    border-right: 0;
  }
`;
export const DetailsLabel = styled.div`
  color: ${(props) =>
    props.primary ? "var(--primary-color)" : "var(--dark-grey-1)"};
  font-weight: 700;
  font-size: 18px;
  @media (max-width: 576px) {
    font-size: 16px;
  }
`;
export const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
`;
export const AdditionalDetails = styled.ul`
  list-style: none;
  margin: 0;
  font-weight: 400;
  padding: 0;
  & li {
    display: inline-block;
    padding: 0 15px;
    font-size: 14px;
    border-right: 1px solid #eaf1f6;

    @media (max-width: 576px) {
      padding: 0 10px;
      font-size: 12px;
      padding-left: ${(props) => props.nopad && 0};
    }

    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
      border-right: none;
    }
  }
`;
export const EditButton = styled.div`
  cursor: pointer;
  @media (max-width: 576px) {
    font-size: 14px;
  }
`;

import styled from "styled-components";
export const Wrapper = styled.div`
  background: #f7f7f9;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 30px 0;
  justify-content: space-around;
  @media (max-width: 576px) {
    display: none;
  }
`;

export const SectionWrapper = styled.section`
  min-height: 90px;
  width: 100%;
  padding: 0 30px;
  & img {
    width: 80%;
    height: 110px;
  }
`;
export const CarDescription = styled.div``;
export const CarName = styled.div`
  margin-bottom: 8px;
  color: var(--tertiary-color);
  font-size: 20px;
  font-weight: 600;
`;
export const CarVariant = styled(CarName)`
  color: var(--dark-grey-1);
  font-size: 16px;
`;
export const EditButton = styled.p`
  cursor: pointer;
  color: var(--primary-color);
  font-weight: 600;
  font-size: 14px;
`;

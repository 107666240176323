import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  makeStyles,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import React from "react";
import "styled-components/macro";
const useAccordionStyles = makeStyles({
  root: {
    marginBottom: "12px",
    boxShadow: "unset",
    border: "0.5px solid #A2ADB2",
    borderRadius: "4px",
    "&::before": {
      background: "#fff",
    },
  },
});
const Accordian = ({ expanded, panel, handleChange, title, children }) => {
  const accordionClasses = useAccordionStyles();
  return (
    <>
      <Accordion
        expanded={expanded}
        onChange={handleChange(panel)}
        classes={accordionClasses}
      >
        <AccordionSummary
          expandIcon={
            <ExpandMore
              style={{
                color: expanded && "var(--primary-color)",
              }}
            />
          }
          aria-controls={`pane${panel}bh-content`}
          id={`pane${panel}bh-header`}
        >
          <div
            css={`
              color: ${expanded
                ? "var(--primary-color)"
                : "var(--dark-grey-1)"};
              font-size: 18px;
              font-weight: 600;
            `}
          >
            {title}
          </div>
        </AccordionSummary>
        <AccordionDetails>{children}</AccordionDetails>
      </Accordion>
    </>
  );
};

export default Accordian;

import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  regNo: "",
  noNumberCarDetails: {
    BRAND_ID: "",
    BRAND_TITLE: "",
    MODEL_ID: "",
    MODEL_NAME: "",
    TYPE_OF_FUEL: "",
    VARIANT_NAME: "",
    VERSION_ID: "",
    rto_id: "",
    rto_name: "",
    registration_year: "",
  },
  fallBack: {
    type: "landing",
    lastActive: "",
  },
  formData: {
    is_policy_expired: "N",
    policy_expired_before_90_days: "N",
    is_claim_made_last_year: "N",
    mobile_no: "",
  },
};
const LandingSlice = createSlice({
  name: "input",
  initialState,
  reducers: {
    setRegNo: (state, { payload }) => {
      state.regNo = payload;
    },
    setBrandDetails: (state, { payload }) => {
      const { BRAND_ID, BRAND_TITLE } = payload;
      state.noNumberCarDetails.BRAND_ID = BRAND_ID;
      state.noNumberCarDetails.BRAND_TITLE = BRAND_TITLE;
    },
    setNoNumberCarDetails: (state, { payload }) => {
      state.noNumberCarDetails = payload;
    },
    setModelDetails: (state, { payload }) => {
      const { MODEL_ID, MODEL_NAME } = payload;
      state.noNumberCarDetails.MODEL_ID = MODEL_ID;
      state.noNumberCarDetails.MODEL_NAME = MODEL_NAME;
    },
    setFuelDetails: (state, { payload }) => {
      state.noNumberCarDetails.TYPE_OF_FUEL = payload;
    },
    setFallbackToLanding: (state) => {
      state.fallBack.type = "LANDING";
      state.fallBack.lastActive = "";
    },
    setFallbackToNoCar: (state, { payload }) => {
      state.fallBack.type = "NOCAR";
      state.fallBack.lastActive = payload.lastActive;
    },
    setFallbackToExpiry: (state, { payload }) => {
      state.fallBack.type = "EXPIRY_FORM";
      state.fallBack.lastActive = payload || 0;
    },
    setVariantDetails: (state, { payload }) => {
      state.noNumberCarDetails.VARIANT_NAME = payload.VARIANT_NAME;
      state.noNumberCarDetails.VERSION_ID = payload.VERSION_ID;
    },
    setRtoDetails: (state, { payload }) => {
      state.noNumberCarDetails.rto_id = payload.id;
      state.noNumberCarDetails.rto_name = payload.label;
    },
    setRegistrationYear: (state, { payload }) => {
      state.noNumberCarDetails.registration_year = payload;
    },
    clearNoCarDetails: (state) => {
      state.noNumberCarDetails = initialState.noNumberCarDetails;
    },
    setFormData: (state, { payload }) => {
      state.formData = payload;
    },
  },
});
export const {
  setBrandDetails,
  setModelDetails,
  setFuelDetails,
  setFallbackToExpiry,
  setFallbackToLanding,
  setFallbackToNoCar,
  setVariantDetails,
  clearNoCarDetails,
  setRegistrationYear,
  setRtoDetails,
  setFormData,
  setNoNumberCarDetails,
  setRegNo,
} = LandingSlice.actions;
export const noNumberSelector = (state) => state.input.noNumberCarDetails;
export const fallBackSelector = (state) => state.input.fallBack;
export default LandingSlice.reducer;

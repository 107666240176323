import React, { useEffect, useState } from "react";
import TextInput from "../TextInput/TextInput";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { styled } from "@material-ui/styles";

const AutoCompleteSelect = ({
  label,
  options = [],
  size,
  _key,
  getOptionLabel,
  getOptionSelected,
  loading,
  handleChange = () => {},
  selectedValue,
  error,
  customInput,
  ...props
}) => {
  const [value, setValue] = useState(null);
  const [customOption, setCustomOption] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const onChange = (e, value) => {
    setValue(value);
    handleChange(value);
    setInputValue("");
  };

  useEffect(() => {
    if (
      selectedValue &&
      selectedValue instanceof Object &&
      Object.keys(selectedValue).length
    )
      setValue(selectedValue);
    if (_key && selectedValue && selectedValue[_key]) {
      setInputValue(selectedValue[_key]);
    } else setInputValue(selectedValue);
  }, [selectedValue, _key, options]);
  console.log(selectedValue);
  const onInputChange = (e, newValue) => {
    const replacedChar = Number(newValue.replace(/[^0-9]/g, ""));
    setInputValue(customInput ? String(replacedChar) : newValue);
    if (customInput) {
      if (
        replacedChar >= customInput.min &&
        replacedChar <= customInput.max &&
        !options.some((item) => Number(item) === replacedChar)
      )
        setCustomOption([String(replacedChar)]);
      else setCustomOption([]);
    }
  };
  return (
    <>
      <CustomAutoComplete
        disablePortal
        disableClearable
        blurOnSelect
        options={customInput ? [...customOption, ...options] : options}
        onChange={onChange}
        onInputChange={onInputChange}
        getOptionLabel={getOptionLabel}
        getOptionSelected={getOptionSelected}
        value={value || null}
        inputValue={inputValue || ""}
        {...props}
        renderInput={(params) => (
          <TextInput
            {...params}
            {...props}
            error={error}
            value={inputValue || ""}
            label={label}
            size={size}
          />
        )}
      />
    </>
  );
};

export default React.memo(AutoCompleteSelect);
const CustomAutoComplete = styled(Autocomplete)({
  "& .MuiAutocomplete-inputRoot": {
    padding: 0,
    "& .MuiAutocomplete-input": {
      padding: "18.5px 14px !important",
    },
  },
});

import styled from "styled-components";
import landing from "./../../assets/images/landing.png";
import landing_mobile from "./../../assets/images/landing_mobile.png";
export const Hero = styled.div`
  background: url(${landing}) no-repeat;
  background-size: contain;
  width: 100%;
  min-height: 540px;
  height: 1px;
  position: relative;
  @media (max-width: 991px) {
    display: flex;
    flex-direction: column;
    background-position: bottom;
    height: auto;
  }
  @media (max-width: 576px) {
    display: flex;
    flex-direction: column;
    background: url(${landing_mobile}) no-repeat;
    background-position: bottom;
    height: auto;
  }
`;

import styled from "styled-components";

export const EndNoteWrapper = styled.div`
  padding: 0 30px;
  text-align: center;
  margin-top: 30px;
`;
export const Message = styled.div`
  color: #82a3ad;
  margin-bottom: 20px;
`;
export const Copyright = styled.div`
  & a {
    font-weight: 600;
  }
`;

import { Alert } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import "styled-components/macro";
import AlertComp from "./AlertComp";
const NoQuotes = () => {
  const { notGenerated } = useSelector((state) => state.quotes);
  if (!notGenerated.length) return <></>;
  return (
    <>
      <Wrapper>
        <div
          css={`
            font-size: 20px;
            font-weight: 600;
            margin-bottom: 20px;
            @media (max-width: 576px) {
              font-size: 16px;
              margin-bottom: 12px;
            }
          `}
        >
          We did not get a quote from the following insurers
        </div>
        <Insurers>
          {notGenerated.map((item) => (
            <AlertComp
              key={item.endPoint}
              logo={item.logo}
              message={item.message}
              endPoint={item.endPoint}
            />
          ))}
        </Insurers>
        <div>
          your location or vehicle model/age is not serviced by the insurer OR
          insurer is not rechable to provide live quotes currently{" "}
        </div>
      </Wrapper>
    </>
  );
};

export default NoQuotes;
const Wrapper = styled.div`
  margin-top: 60px;
  display: flex;
  min-height: 200px;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: space-evenly;
  background: #ffffff;
  border: 1px dashed #bcbcbc;
  box-sizing: border-box;
  padding: 20px 16px;
  @media (max-width: 576px) {
    text-align: center;
    padding: 20px 16px;
  }
`;
const Insurers = styled.div`
  display: flex;
  gap: 12px 0;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: column;
  width: 100%;
  max-width: 600px;
  margin-bottom: 20px;
  & .message-quotes {
    display: flex;
    align-items: center;
    @media (max-width: 576px) {
      flex-direction: column;
      text-align: left;
      align-items: start;
    }
    & .image-wrapper {
      width: 100px;
    }
  }
  & img {
    height: 24px;
    width: auto;
    max-width: 100px;
    margin-right: 20px;
    @media (max-width: 576px) {
      margin-bottom: 8px;
    }
  }
  @media (max-width: 576px) {
    margin: 16px 0;
  }
`;

import { createSlice } from "@reduxjs/toolkit";
const getTotalPremium = (quote, selected_addons) => {
  const available_addons = quote?.PREMIUM_DATA?.PREMIUM_DATA?.ADDONS?.CAN_OPT;
  let added = [];
  Object.keys(available_addons || {}).forEach((item) => {
    if (
      selected_addons.includes(item) &&
      available_addons[item] !== "NOT FOUND" &&
      available_addons[item] !== "Not Found"
    ) {
      added = [...added, { slug: item, premium: available_addons[item] }];
    }
  });
  const addOnPremium = added.reduce(
    (prev, cur) => prev + parseInt(cur.premium),
    0
  );
  return (
    parseInt(quote?.PREMIUM_DATA?.PREMIUM_DATA?.TOTAL_PREMIUM) + addOnPremium
  );
};
const initialState = {
  quote_end_points: [],
  quotes: [],
  selected_addons: [],
  addons_name_slug: {},
  plan_type: {
    code: "ALL",
    display_name: "All Plans",
  },
  IDV: {
    min: Infinity,
    max: 0,
  },
  showPolicyDetails: false,
  notGenerated: [],
  addOnsWithCover: {},
  showPaDialog: false,
  selectedQuoteDetails: {},
};

const quoteSlice = createSlice({
  name: "quotes",
  initialState,
  reducers: {
    setShowPolicyDetails: (state, { payload }) => {
      state.showPolicyDetails = payload;
    },
    setQuoteEndPoints: (state, { payload }) => {
      state.quote_end_points = payload;
    },
    setQuotes: (state, { payload }) => {
      state.quotes = [...state.quotes, payload].sort(
        (a, b) =>
          getTotalPremium(a, state.selected_addons) -
          getTotalPremium(b, state.selected_addons)
      );
    },
    sortQuotes: (state) => {
      state.quotes = state.quotes.sort(
        (a, b) =>
          getTotalPremium(a, state.selected_addons) -
          getTotalPremium(b, state.selected_addons)
      );
    },
    setSelectedAddons: (state, { payload }) => {
      state.selected_addons = [...state.selected_addons, payload];
    },
    removeAddon: (state, { payload }) => {
      state.selected_addons = state.selected_addons.filter(
        (item) => item !== payload
      );
    },
    updateAddons: (state, { payload }) => {
      state.addons_name_slug = payload;
    },
    setPlanTypes: (state, { payload }) => {
      state.plan_type = payload;
    },
    setIDVRange: (state, { payload }) => {
      state.IDV.min = Math.min(
        state.IDV.min,
        parseInt(payload.IDV_MIN) || Infinity
      );
      state.IDV.max = Math.max(state.IDV.max, parseInt(payload.IDV_MAX || 0));
    },
    setNotGenerated: (state, { payload }) => {
      if (
        !state.notGenerated.some((item) => item.endPoint === payload.endPoint)
      ) {
        state.notGenerated = [...state.notGenerated, payload];
      }
    },
    clearNotGenerated: (state, { payload }) => {
      state.notGenerated = [];
    },
    setAddOnsWithCover: (state, { payload }) => {
      state.addOnsWithCover[payload.key] = payload.value;
    },
    setShowPaDialog: (state, { payload }) => {
      state.showPaDialog = payload;
    },
    setSelectedQuotesDetails: (state, { payload }) => {
      state.selectedQuoteDetails = payload;
    },
    removeNotGenerated: (state, { payload }) => {
      state.notGenerated = state.notGenerated.filter(
        (item) => item.endPoint !== payload
      );
    },
  },
});
export const {
  setQuoteEndPoints,
  clearNotGenerated,
  setSelectedAddons,
  removeAddon,
  updateAddons,
  setPlanTypes,
  setQuotes,
  setIDVRange,
  sortQuotes,
  setNotGenerated,
  setShowPolicyDetails,
  setAddOnsWithCover,
  setShowPaDialog,
  setSelectedQuotesDetails,
  removeNotGenerated,
} = quoteSlice.actions;

export const selectedAddonsSelector = (state) => state.quotes.selected_addons;
export const addOnsSlugSelector = (state) => state.quotes.addons_name_slug;
export const planTypeSelector = (state) => state.quotes.plan_type;
export const IDVrangeSelector = (state) => state.quotes.IDV;
export default quoteSlice.reducer;

import moment from "moment";
import React from "react";
import styled from "styled-components";
const NCB = ({ label, value, onClick, registration_date }) => {
  const values = [0, 20, 25, 35, 45, 50];
  // const differenceInYears = moment().diff(
  //   moment(registration_date, "YYYY-MM-DD"),
  //   "years"
  // );
  // const valuesToMap = values.filter((val, idx) => idx < differenceInYears);

  return (
    <>
      <Label>{label}</Label>
      <PercentageWrapper>
        {values.map((item) => (
          <Percentage
            onClick={() => {
              onClick(item);
            }}
            selected={value === item}
          >
            {item}%
          </Percentage>
        ))}
      </PercentageWrapper>
    </>
  );
};

export default NCB;
const PercentageWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 20px;
  height: 40px;
  align-items: center;
`;
const Percentage = styled.div`
  cursor: pointer;
  flex: 1 1;
  height: 100%;
  border: 1px solid #eaf1f6;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 8px rgba(0, 110, 144, 0.04);
  color: ${(props) => props.selected && "#fff"};
  background: ${(props) => props.selected && "var(--primary-color)"};
  &:hover {
    box-shadow: 0px 4px 8px rgba(0, 110, 144, 0.2);
  }
  transition: all 0.2s;
`;
const Label = styled.div`
  color: var(--tertiary-color);
  margin-bottom: 12px;
`;

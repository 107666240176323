import { Dialog, DialogTitle, Divider, useMediaQuery } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import PlanCard from "./components/PlanCard/PlanCard";
import ProposalForm from "./components/ProposalForm/ProposalForm";
import { ProposalPageWrapper } from "./ProposalForm.style";
import "styled-components/macro";
import { Button } from "../../components/Button/Button";
import { useNavigate } from "react-router-dom";
import { useProposalEnquiry } from "../../services/ProposalServices";
const Proposal = () => {
  const { inspectionInfo, showInspectionPopup } = useSelector(
    (state) => state.proposal
  );
  const navigate = useNavigate();
  const { isLoading } = useProposalEnquiry();
  const fullScreen = useMediaQuery("(max-width:576px)");
  if (isLoading) return <></>;
  return (
    <>
      <ProposalPageWrapper>
        <PlanCard />
        <ProposalForm />
      </ProposalPageWrapper>
      <Dialog
        open={showInspectionPopup}
        fullWidth
        maxWidth={"xs"}
        disableEnforceFocus
        fullScreen={fullScreen}
      >
        <div
          css={`
            padding: 20px 20px;
          `}
        >
          <div>
            <h3
              css={`
                color: var(--primary-color);
              `}
            >
              Congratulations ! Your proposal is submitted successfully.
            </h3>{" "}
            Because of break-in your vehicle has to go through simple inspection
            process. Please keep below inspection id for your reference. You
            will receive an email regarding the process.
          </div>
          <div
            css={`
              margin-top: 20px;
            `}
          >
            <strong
              css={`
                color: var(--primary-color);
              `}
            >
              Inspection Id:
            </strong>{" "}
            {inspectionInfo.INSPECTION_ID}
          </div>
        </div>
        <div
          css={`
            display: flex;
            justify-content: center;
          `}
        >
          <Button
            onClick={() => {
              navigate("/");
            }}
            primary
            css={`
              margin-bottom: 20px;
              width: 80px;
              text-align: center;
              padding: 12px 0;
            `}
          >
            OK
          </Button>
        </div>
      </Dialog>
    </>
  );
};

export default Proposal;

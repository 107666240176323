import styled from "styled-components";

export const ShareQuotesWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  border: 1px solid #eaf1f6;
  padding: 20px 20px;
  justify-content: space-between;
  @media (max-width: 576px) {
    display: none;
  }
`;
export const ShareQuotesSection = styled.div`
  height: 100%;
  @media (max-width: 576px) {
    padding: 12px 0;
    display: flex;
    gap: 8px;
  }
`;

export const ShareQuotesWrapperMobile = styled.div`
  display: none;
  @media (max-width: 576px) {
    display: flex;
    flex-direction: column;
    border: 1px solid #eaf1f6;
    padding: 12px;
  }
`;

export const ShareQuotesWrapperRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const FormWrapper = styled.div`
  max-width: 300px;
  margin: 20px auto 0;
  padding: 0 12px;
`;

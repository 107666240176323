import styled from "styled-components";

export const SectionTitle = styled.div`
  font-size: 34px;
  font-weight: 700;
  text-align: ${(props) => props.center && "center"};
  margin-bottom: 50px;
  & .primary {
    color: var(--primary-color);
    & .secondary {
      color: var(--secondary-color);
    }
  }
  text-transform: capitalize;

  @media (max-width: 991px) {
    font-size: 24px;
    text-align: center;
  }
  @media (max-width: 576px) {
    font-size: 20px;
    text-align: center;
  }
`;
export const CardWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  @media (max-width: 576px) {
    justify-content: center;
  }
  @media (max-width: 991px) {
    justify-content: center;
    gap: 10px;
  }
`;

import React, { useState } from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import "./ShareButton.scss";
import ShareDialog from "./ShareDialog";
import { shareTypes } from "./utils";

const ShareButton = () => {
  const location = useLocation();
  const [show, setShow] = useState(false);
  useEffect(() => {
    const shareButton = document.querySelectorAll("button.shareButton");
    const open = () => {
      for (let i = 0; i < shareButton.length; i++) {
        shareButton[i].classList.toggle("open");
        shareButton[0].classList.remove("sent");
      }
    };
    const openOthers = () => {
      for (let i = 0; i < shareButton.length; i++) {
        shareButton[i].classList.toggle("open");
      }
      // shareButton[0].classList.toggle("sent");
    };
    if (shareButton[0]) {
      shareButton[0].addEventListener("click", open);

      for (let i = 1; i < shareButton.length; i++) {
        shareButton[i].addEventListener("click", openOthers);
      }
    }
    return () => {
      if (shareButton[0]) {
        shareButton[0].removeEventListener("click", open);
        for (let i = 1; i < shareButton.length; i++) {
          shareButton[i].removeEventListener("click", openOthers);
        }
      }
    };
  }, [location.pathname]);
  if (location.pathname === "/") return <></>;
  else
    return (
      <>
        <ShareDialog
          onClose={() => setShow(false)}
          open={Boolean(show)}
          type={show}
        />
        <div className='content'>
          <button class='shareButton main'>
            <svg
              class='share'
              style={{ width: "20px", height: "20px" }}
              viewBox='0 0 24 24'
            >
              <path d='M18,16.08C17.24,16.08 16.56,16.38 16.04,16.85L8.91,12.7C8.96,12.47 9,12.24 9,12C9,11.76 8.96,11.53 8.91,11.3L15.96,7.19C16.5,7.69 17.21,8 18,8A3,3 0 0,0 21,5A3,3 0 0,0 18,2A3,3 0 0,0 15,5C15,5.24 15.04,5.47 15.09,5.7L8.04,9.81C7.5,9.31 6.79,9 6,9A3,3 0 0,0 3,12A3,3 0 0,0 6,15C6.79,15 7.5,14.69 8.04,14.19L15.16,18.34C15.11,18.55 15.08,18.77 15.08,19C15.08,20.61 16.39,21.91 18,21.91C19.61,21.91 20.92,20.61 20.92,19A2.92,2.92 0 0,0 18,16.08Z' />
            </svg>
            <svg
              class='check'
              style={{ width: "20px", height: "20px" }}
              viewBox='0 0 24 24'
            >
              {" "}
              <path d='M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z' />
            </svg>
            <svg
              class='close'
              style={{ width: "20px", height: "20px" }}
              viewBox='0 0 24 24'
            >
              <path d='M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z' />
            </svg>
          </button>
          <button
            class='shareButton fb'
            onClick={() => setShow(shareTypes.WHTS)}
          >
            <i class='fas fa-sms'></i>
          </button>
          <button
            class='shareButton tw'
            onClick={() => setShow(shareTypes.MAIL)}
          >
            <i class='far fa-envelope'></i>
          </button>
          <button
            class='shareButton ig'
            onClick={() => {
              navigator.clipboard.writeText(window.location).then(() => {
                toast.success("Link Copied !");
              });
            }}
          >
            <i class='far fa-copy'></i>
          </button>
        </div>
      </>
    );
};

export default ShareButton;

import styled from "styled-components";
import modalback from "./../../../../assets/images/modalbackground.png";
export const Wrapper = styled.div`
  padding: 40px 28px 30px;
  margin: auto;
  min-height: 580px;
  width: 100%;
  background: url(${modalback}) no-repeat bottom;
  @media (max-width: 576px) {
    padding: 0px 18px 20px;
    height: 100%;
    min-height: unset;
  }
`;
export const Header = styled.div`
  display: flex;
  margin: auto;
  align-items: flex-start;
  justify-content: space-between;
  width: 90%;
  margin-bottom: 20px;
  @media (max-width: 576px) {
    width: 100%;
    align-items: center;
  }
`;
export const FormName = styled.div`
  color: ${(props) =>
    props.active ? "var(--primary-color)" : "var(--dark-grey-1)"};
  font-weight: ${(props) => (props.active ? 700 : 600)};
  text-align: center;
  position: relative;
  flex: 1 1;
  @media (max-width: 576px) {
    font-size: 12px;
  }
`;
export const FormSubName = styled.span`
  color: ${(props) =>
    props.active ? "var(--primary-color)" : "var(--dark-grey-1)"};
  font-weight: ${(props) => (props.active ? 700 : 600)}; ;
`;

export const Question = styled.div`
  font-weight: 700;
  font-size: 30px;
  text-transform: capitalize;
  text-align: center;
  margin-bottom: 20px;
  & span {
    color: var(--secondary-color);
  }
  @media (max-width: 576px) {
    font-size: 22px;
  }
`;

export const TextInput = styled.input`
  background: #ffffff;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  border: none;
  width: 100%;
  height: 50px;
  padding: 0 26px;

  &:focus {
    box-shadow: 0 4px 14px rgba(0, 0, 0, 0.2);
    outline: none;
  }
`;
export const InputWrapper = styled.div`
  position: relative;
  display: block;
  margin: 0 auto;
  width: 60%;
  text-align: center;
  margin-bottom: 30px;
  & img {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
  }
  @media (max-width: 576px) {
    width: 100%;
  }
`;

export const ExpandableBox = styled.div`
  display: flex;
  width: 90%;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: auto;
  background-color: #fff;
  max-height: ${(props) => (props.expand ? "280px" : "240px")};
  padding: 20px 20px;
  overflow-y: ${(props) => (props.expand ? "auto" : "hidden")};
  margin-bottom: 20px;
  box-shadow: ${(props) => props.expand && "0px 4px 8px rgba(0, 0, 0, 0.08)"};
  &::-webkit-scrollbar {
    width: 4px;
    border-radius: 8px;
  }
  &::-webkit-scrollbar-track {
    background: rgba(9, 114, 147, 0.2);
  }
  &::-webkit-scrollbar-thumb {
    background: rgba(0, 110, 144, 0.8);
  }
  @media (max-width: 576px) {
    padding: 20px 0;
  }
`;
export const ExpandableCarBox = styled(ExpandableBox)`
  max-height: ${(props) => (props.expand ? "280px" : "140px")};
  margin-bottom: 30px;
`;
export const FuelBox = styled(ExpandableCarBox)`
  height: auto;
  max-height: unset;
  margin-top: 50px;
`;
export const ModelBox = styled(ExpandableCarBox)`
  display: flex;
  justify-content: center;
`;
export const BrandWrapper = styled.div`
  width: calc(20% - 20px);
  margin: 0 10px 0;
  height: 100px;
  position: relative;
  margin-bottom: 20px;
  border: ${(props) =>
    props.active ? "0.3px solid #eaf1f6" : "0.5px solid #eaf1f6"};
  color: ${(props) =>
    props.active ? "var(--primary-color)" : "var(--dark-grey-1)"};

  background-color: #fff;
  box-shadow: ${(props) =>
    props.active
      ? "0px 6px 16px rgba(0, 110, 144, 0.12)"
      : "0px 4px 8px rgba(0, 110, 144, 0.02)"};
  cursor: pointer;
  & img,
  & div {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  & img {
    width: auto;
    height: auto;
    max-width: 80%;
    max-height: 60%;
  }
  &:hover {
    border: 0.3px solid #eaf1f6;
    box-shadow: 0px 6px 16px rgba(0, 110, 144, 0.14);
  }
  @media (max-width: 991px) {
    width: calc(25% - 20px);
  }
  @media (max-width: 576px) {
    width: calc(33.3% - 20px);
  }
`;
export const CarWrapper = styled(BrandWrapper)`
  height: unset;
  padding: 12px 0;
  width: calc(25% - 20px);

  & div {
    position: relative;
    text-align: center;
    font-size: 14px;
    font-weight: ${(props) => (props.active ? "700" : "400")};
  }
  @media (max-width: 991px) {
    width: calc(33.3% - 20px);
  }
`;
export const FuelWrapper = styled(CarWrapper)`
  width: calc(50% - 20px);
  @media (max-width: 576px) {
    width: 100%;
    text-align: left;
  }
`;
export const ModelWrapper = styled(FuelWrapper)`
  width: calc(33% - 20px);
  @media (max-width: 991px) {
    text-align: center;
    width: calc(50% - 20px);
  }
`;
export const RegistrationWrapper = styled.div`
  margin-top: 24px;
  padding: 0 40px;
  text-align: center;
  @media (max-width: 576px) {
    padding: 0;
  }
`;
export const ExpandButtonWrapper = styled.div`
  width: 60%;
  margin: auto;
`;

export const FormLabel = styled.div`
  position: relative;
  display: none;
  margin-bottom: 20px;
  @media (max-width: 576px) {
    display: block;
  }
`;

export const BackButton = styled.div`
  display: inline-block;
  position: absolute;
  width: 20px;
  height: auto;
  color: var(--dark-grey-1);
  @media (max-width: 576px) {
    & img {
      width: 100%;
      height: auto;
    }
  }
`;
export const Text = styled.div`
  display: block;
  text-align: center;
`;

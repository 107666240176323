import { Dialog, Divider, makeStyles } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { useEnquiryQuery } from "../../../../../services/CarServices";
import { planTypeSelector, setPlanTypes } from "../../../QuotesPage.slice";
import {
  CloseButton,
  DialogTitle,
} from "../../EditDetailsDialog/EditDetailsDialog.style";
const useStyles = makeStyles({
  paper: {
    padding: "0 12px",
  },
});
const PlanTypes = ({ open = true, onClose = () => {} }) => {
  const classes = useStyles();
  const plan_type = useSelector(planTypeSelector);
  const dispatch = useDispatch();
  const { requestData, isLoading } = useEnquiryQuery();
  useEffect(() => {
    if (requestData && requestData.policy_type.length === 1) {
      dispatch(setPlanTypes(requestData.policy_type[0]));
    } else
      dispatch(
        setPlanTypes({
          code: "ALL",
          display_name: "All Plans",
        })
      );
  }, [requestData]);
  if (isLoading) return <></>;
  const { policy_type } = requestData;
  const showAll = policy_type.length > 1;
  const onClick = (e) => {
    const val = e.currentTarget.getAttribute("data-key");
    dispatch(
      setPlanTypes({
        code: val,
        display_name: e.currentTarget.children[0].innerText,
      })
    );
    onClose();
  };
  return (
    <>
      <Dialog
        open={open}
        classes={classes}
        onClose={onClose}
        fullWidth
        maxWidth={"xs"}
      >
        <DialogTitle>
          <div>Plan Types</div>
          <CloseButton onClick={() => onClose()}>
            <Close />
          </CloseButton>
        </DialogTitle>
        <Divider />
        <PlanTypesWrapper>
          {showAll && (
            <>
              {" "}
              <PlanTypeName
                data-key={"ALL"}
                selected={plan_type.code === "ALL"}
                onClick={onClick}
              >
                <div className='name'>All Plans</div>
              </PlanTypeName>
              <Divider />
            </>
          )}
          {policy_type.map((item) => (
            <>
              <PlanTypeName
                data-key={item.code}
                onClick={onClick}
                selected={plan_type.code === item.code}
              >
                <div className='name'>{item.display_name} </div>
                <div className='note'>{item.description}</div>
              </PlanTypeName>
              <Divider />
            </>
          ))}
        </PlanTypesWrapper>
      </Dialog>
    </>
  );
};

export default PlanTypes;
const PlanTypesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  margin-bottom: 60px;
  padding: 0 16px;
  @media (max-width: 576px) {
    margin-top: 12px;
    padding: 0 8px;
  }
`;
const PlanTypeName = styled.div`
  display: flex;
  height: 48px;
  flex-direction: column;
  margin: 12px 0;
  padding: 0 12px;
  justify-content: space-between;
  & .name {
    font-size: 18px;
    font-weight: 700;
    color: ${(props) => props.selected && "var(--secondary-color)"};
  }
  & .note {
    color: var(--dark-grey-1);
    font-size: 12px;
  }
  @media (max-width: 576px) {
    height: 34px;
    & .name {
      font-size: 16px;
    }
    & .note {
      font-size: 10px;
    }
  }
`;

import styled from "styled-components";

export const AddressLines = styled.p`
  margin-bottom: 0px;
  line-height: 27px;
  font-size: 14px;
  color: #4d4d4d;
`;
export const AddressWrapper = styled.div`
  margin-bottom: 30px;
`;
export const LogoWrapper = styled.div`
  margin-bottom: 20px;
`;

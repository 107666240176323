import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import { Divider, useMediaQuery } from "@mui/material";
import { makeStyles } from "@material-ui/core";
import { useSelector } from "react-redux";
import {
  CloseButton,
  DialogTitle,
} from "../../pages/QuotesPage/components/EditDetailsDialog/EditDetailsDialog.style";
import { Close } from "@material-ui/icons";
import { InputWrapper } from "../../pages/Proposal/ProposalForm.style";
import TextInput from "../TextInput/TextInput";
import { shareTypes } from "./utils";
import { Button } from "../Button/Button";
import { ButtonsWrapper } from "../../pages/Landing/components/InputForm/InputForm.style";
import {
  useSendPaymentMutation,
  useSendProposalMutation,
  useSendQuoteMutation,
} from "../../services/EmailServices";
import * as yup from "yup";
import { useLocation } from "react-router-dom";
import ShareSelectedQuotes from "./ShareSelectedQuotes";
import { FormWrapper } from "./ShareSelectedQuotes.style";
import { onInputPhone } from "../../utils/onInput";
const useStyles = makeStyles({
  paper: {
    padding: "0 12px",
  },
});
const ShareDialog = ({ open, onClose, type }) => {
  const fullScreen = useMediaQuery("(max-width:576px)");
  const classes = useStyles();
  const [data, setData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    mobile_no: "",
  });
  const validator = yup.object().shape({
    first_name: yup.string().required(),
    last_name: yup.string().required(),
    ...(shareTypes.MAIL === type
      ? { email: yup.string().email().required() }
      : {}),
    ...(shareTypes.WHTS === type ? { mobile_no: yup.string().required() } : {}),
  });
  const location = useLocation();
  const [errors, setErrors] = useState({});
  const onChangeValidate = async (name, val) => {
    setData((prev) => ({ ...prev, [name]: val }));
    console.log("here");
    validator
      .validateAt(name, {
        ...data,
        [name]: val,
      })
      .then(() => {
        setErrors((prev) => ({ ...prev, [name]: "" }));
      })
      .catch((error) => {
        setErrors((prev) => ({ ...prev, [name]: error.message }));
      });
  };
  const isQuotesPage = location.pathname === "/quotes";
  const onSubmit = () => {
    validator
      .validate(data, { abortEarly: false })
      .then(() => {
        if (location.pathname === "/quotes")
          sendQuoteEmail({ ...data, quotes: quotesToShare, type });
        if (location.pathname === "/proposal")
          sendProposalEmail({ ...data, type });
        if (location.pathname === "/summary")
          sendPaymentEmail({ ...data, type });
      })
      .catch((error) => {
        error.inner.forEach((item) => {
          setErrors((prev) => ({ ...prev, [item.path]: item.message }));
        });
      });
  };
  const { sendQuoteEmail } = useSendQuoteMutation();
  const { sendProposalEmail } = useSendProposalMutation();
  const { sendPaymentEmail } = useSendPaymentMutation();
  const { quotes } = useSelector((state) => state.quotes);

  const onChange = (e) => {
    onChangeValidate(e.target.name, e.target.value);
  };
  const [quotesToShare, setQuotesToShare] = useState();
  const [isSelected, setIsSelected] = useState();
  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        fullWidth
        maxWidth={isQuotesPage ? "md" : "xs"}
        fullScreen={fullScreen}
        classes={classes}
        disableEnforceFocus
      >
        <DialogTitle>
          <div>Share Details</div>
          <CloseButton onClick={() => onClose()}>
            <Close />
          </CloseButton>
        </DialogTitle>
        <Divider />
        {isQuotesPage && (
          <ShareSelectedQuotes
            allQuotes={quotes}
            quotesToShare={quotesToShare}
            setQuotesToShare={setQuotesToShare}
            setIsSelected={setIsSelected}
            isSelected={isSelected}
          />
        )}

        {((isSelected && quotesToShare.length) || !isQuotesPage) && (
          <FormWrapper>
            <InputWrapper>
              <TextInput
                name='first_name'
                value={data?.first_name}
                onChange={onChange}
                size={"medium"}
                error={errors.first_name}
                label={"First Name"}
              />
            </InputWrapper>
            <InputWrapper>
              <TextInput
                name='last_name'
                value={data?.last_name}
                onChange={onChange}
                size={"medium"}
                error={errors.last_name}
                label={"Last Name"}
              />
            </InputWrapper>
            {shareTypes.MAIL === type ? (
              <InputWrapper>
                <TextInput
                  name='email'
                  type='email'
                  value={data?.email}
                  onChange={onChange}
                  size={"medium"}
                  label={"Email"}
                  error={errors.email}
                />
              </InputWrapper>
            ) : (
              <InputWrapper>
                <TextInput
                  name='mobile_no'
                  type='tel'
                  value={data?.mobile_no}
                  onChange={onChange}
                  onInput={onInputPhone}
                  size={"medium"}
                  maxLength={10}
                  label={"Mobile No"}
                  error={errors.mobile_no}
                />
              </InputWrapper>
            )}

            <Button
              primary
              fullWidth
              style={{ marginBottom: "20px" }}
              onClick={onSubmit}
            >
              Share
            </Button>
          </FormWrapper>
        )}
      </Dialog>
    </>
  );
};

export default ShareDialog;

import styled from "styled-components";
export const SocialList = styled.div`
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: space-between;
  gap: 30px;
  @media (max-width: 576px) {
    justify-content: flex-start;
    gap: 30px;
  }
`;
export const SocialItem = styled.a`
  display: inline-block;
  cursor: pointer;
`;

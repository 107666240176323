import { useEffect, useRef } from "react";

const useEffectExceptOnMount = (effect, dependencies) => {
  const mounted = useRef(false);
  useEffect(() => {
    if (mounted.current) {
      const unmount = effect();
      return () => {
        mounted.current = false;
        unmount && unmount();
      };
    } else {
      mounted.current = true;
    }
  }, dependencies);
};
export default useEffectExceptOnMount;

import { Divider } from "@material-ui/core";
import React from "react";
import styled from "styled-components";
const GarageCard = ({ name, address, phone }) => {
  return (
    <>
      <Wrapper>
        <LeftWrapper>
          <Name>{name}</Name>
          <Address>
            <i class='fas fa-map-marker-alt'></i>
            {"  "} {address}
          </Address>
        </LeftWrapper>
        <Phone>
          <i class='fas fa-phone-alt'></i> {"  "}
          {phone}
        </Phone>
      </Wrapper>
      <Divider />
    </>
  );
};

export default GarageCard;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  margin-top: 20px;
  align-items: flex-end;
`;

const LeftWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 70%;
`;
const Address = styled.div`
  color: var(--dark-grey-2);
  font-weight: 600;
  font-size: 14px;
  @media (max-width: 576px) {
    font-size: 12px;
  }
`;
const Phone = styled(Address)``;
const Name = styled.div`
  font-weight: 600;
  font-size: 20px;
  @media (max-width: 576px) {
    font-size: 16px;
  }
`;

import { Rating, Typography } from "@mui/material";
import React from "react";
import { RatingLabel, RatingsCard } from "../ThankYou.style";

const RatingCard = ({ label }) => {
  return (
    <>
      <RatingsCard>
        <Rating style={{ color: "var(--secondary-color)" }} defaultValue={1} />
        <RatingLabel>{label}</RatingLabel>
      </RatingsCard>
    </>
  );
};

export default RatingCard;

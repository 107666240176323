import "./App.scss";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Landing from "./pages/Landing/Landing";
import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";
import { store } from "./app/store";
import { Provider } from "react-redux";
import { QueryClient, QueryClientProvider } from "react-query";
import QuotesPage from "./pages/QuotesPage/QuotesPage";
import Proposal from "./pages/Proposal/Proposal";
import { ReactQueryDevtools } from "react-query/devtools";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import ThankYou from "./pages/ThankYou/ThankYou";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import ProposalSummary from "./pages/ProposalSummary/ProposalSummary";
import ShareButton from "./components/ShareButton/ShareButton";
import { persistQueryClient } from "react-query/persistQueryClient-experimental";
import { createWebStoragePersistor } from "react-query/createWebStoragePersistor-experimental";
import moment from "moment";
const date = new Date();
const [day, month, year] = [date.getDay(), date.getMonth(), date.getFullYear()];
const date1 = new Date(year, month, day, 0, 0);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      // cacheTime: moment(date1).diff(moment(), "ms"),
      // staleTime: 100000000,
    },
  },
});
const localStoragePersistor = createWebStoragePersistor({
  storage: window.localStorage,
});
persistQueryClient({
  queryClient,
  persistor: localStoragePersistor,
});
function App() {
  return (
    <>
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <Router>
            <ScrollToTop>
              <Navbar />
              <ShareButton />
              <Routes>
                <Route exact path='/' element={<Landing />} />
                <Route exact path='/quotes' element={<QuotesPage />} />
                <Route exact path='/proposal' element={<Proposal />} />
                <Route exact path='/summary' element={<ProposalSummary />} />
                <Route exact path='/thankyou' element={<ThankYou />} />
              </Routes>
              <Footer />
            </ScrollToTop>
          </Router>
          <ToastContainer
            position='bottom-right'
            autoClose={3000}
            limit={3}
            rtl={false}
            pauseOnHover
            theme='colored'
          />

          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </Provider>
    </>
  );
}

export default App;

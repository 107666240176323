import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Company from "./components/Company/Company";
import Contact from "./components/Contact/Contact";
import Address from "./components/Address/Address";
import Social from "./components/Social/Social";
import "styled-components/macro";
import { Copyright, EndNoteWrapper, Message } from "./Footer.style";
const mobPadding = `
  @media(max-width:576px){
    padding:0 30px;
  }
`;
const Footer = () => {
  return (
    <footer
      css={`
        padding: 20px 0;
      `}
    >
      <Container
        css={`
          margin-top: 80px;
        `}
      >
        <Row>
          <Col sm={4}>
            <Contact />
          </Col>
          <Col css={mobPadding} sm={2}>
            <Company />
          </Col>
          <Col css={mobPadding} sm={3}>
            <Address />
          </Col>
          <Col css={mobPadding} sm={3}>
            <Social />
          </Col>
        </Row>
        <EndNoteWrapper>
          <Message>
            Insurance is the subject matter of the solicitation. For more
            details on policy terms, conditions,
            <br /> exclusions, limitations, please refer / read policy brochure
            carefully before concluding sale.
          </Message>
          <Copyright>
            ©️ 2020 vKover. All Rights Reserved{" "}
            <a
              href={`${process.env.REACT_APP_BASE_REDIRECT_URL}/privacy-policy`}
            >
              Privacy Policy
            </a>{" "}
            and{" "}
            <a
              href={`${process.env.REACT_APP_BASE_REDIRECT_URL}/terms-and-conditions`}
            >
              Terms of Use
            </a>
          </Copyright>
        </EndNoteWrapper>
      </Container>
    </footer>
  );
};

export default Footer;

import styled from "styled-components";

export const FormWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;
export const Form = styled.form`
  position: absolute;
  bottom: 20px;
  right: 0;
  box-shadow: 0px 4px 25px rgba(212, 236, 243, 0.83);
  padding: 40px 45px;
  background: #fff;
  @media (max-width: 991px) {
    right: unset;
    bottom: unset;
    position: unset;
    padding: 0 0 20px;
    margin-bottom: 120px;

    box-shadow: 0px 8px 12px rgba(212, 236, 243, 0.2);
  }
`;
export const InputWrapper = styled.div`
  @media (max-width: 991px) {
    padding: 0 16px;
  }
`;
export const ButtonsWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 24px;
  margin-bottom: 50px;
  @media (max-width: 991px) {
    display: none;
  }
`;
export const DontKnowNumber = styled.p`
  color: var(--secondary-color);
  font-weight: 600;
  cursor: pointer;
`;
export const Renew = styled.p`
  text-align: center;
  margin-top: 14px;
  font-size: 14px;
  font-weight: 600;
  color: var(--dark-grey-1);
  cursor: pointer;
`;

import { Button } from "../Button/Button";
import "styled-components/macro";
import {
  ShareQuotesSection,
  ShareQuotesWrapper,
  ShareQuotesWrapperMobile,
  ShareQuotesWrapperRow,
} from "./ShareSelectedQuotes.style";
import { Checkbox, useMediaQuery } from "@material-ui/core";
import {
  Label,
  LogoWrapper,
  QuoteDataWrapper,
  Value,
} from "../../pages/QuotesPage/components/Quotes/components/QuoteCard/QuoteCard.style";
import Check from "../Checkbox/Checkbox";
const ShareSelectedQuotes = ({
  allQuotes,
  quotesToShare,
  setQuotesToShare,
  setIsSelected,
  isSelected,
}) => {
  return (
    <>
      <div
        css={`
          display: flex;
          gap: 12px;
          margin: 20px auto 20px;
        `}
      >
        <Button
          primary={isSelected === "all"}
          secondary={isSelected !== "all"}
          onClick={() => {
            setIsSelected("all");
            setQuotesToShare(allQuotes);
          }}
        >
          Share All Quotes
        </Button>
        <Button
          primary={isSelected === "selected"}
          secondary={isSelected !== "selected"}
          onClick={() => {
            setIsSelected("selected");
            setQuotesToShare([]);
          }}
        >
          Share Selected Quotes
        </Button>
      </div>
      {isSelected === "selected" && (
        <div
          css={`
            display: flex;
            flex-direction: column;
            max-width: 500px;
            margin: 0 auto;
            width: 100%;
          `}
        >
          {allQuotes.map((item) => (
            <>
              <ShareQuotesWrapper>
                <ShareQuotesSection>
                  <Check
                    primary
                    checked={quotesToShare.some(
                      (quote) =>
                        quote.PRODUCT_DATA.PRODUCTID ===
                        item.PRODUCT_DATA.PRODUCTID
                    )}
                    onClick={() => {
                      if (
                        quotesToShare.some(
                          (quote) =>
                            quote.PRODUCT_DATA.PRODUCTID ===
                            item.PRODUCT_DATA.PRODUCTID
                        )
                      ) {
                        setQuotesToShare(
                          quotesToShare.filter(
                            (quote) =>
                              quote.PRODUCT_DATA.PRODUCTID !==
                              item.PRODUCT_DATA.PRODUCTID
                          )
                        );
                        return;
                      }
                      setQuotesToShare([...quotesToShare, item]);
                    }}
                  />
                </ShareQuotesSection>
                <ShareQuotesSection>
                  <img
                    css={`
                      width: 136px;
                      height: 40px;
                    `}
                    src={item.PRODUCT_DATA.LOGO_PATH}
                    alt='logo'
                  />
                </ShareQuotesSection>
                <ShareQuotesSection>
                  <Label>Cover Value :</Label>
                  <Value>
                    <>
                      ₹{" "}
                      {parseInt(
                        item.PREMIUM_DATA.PREMIUM_DATA?.IDV
                      ).toLocaleString("en-IN")}
                    </>
                  </Value>
                </ShareQuotesSection>
                <ShareQuotesSection>
                  <Label>Premium :</Label>
                  <Value>
                    <>
                      ₹{" "}
                      {parseInt(
                        item.PREMIUM_DATA.PREMIUM_DATA?.TOTAL_PREMIUM
                      ).toLocaleString("en-IN")}
                    </>
                  </Value>
                </ShareQuotesSection>
              </ShareQuotesWrapper>
              {
                <>
                  <ShareQuotesWrapperMobile>
                    <ShareQuotesWrapperRow>
                      <img
                        css={`
                          width: 136px;
                          height: 40px;
                        `}
                        src={item.PRODUCT_DATA.LOGO_PATH}
                        alt='logo'
                      />
                      <ShareQuotesSection>
                        <Button secondary>
                          ₹{" "}
                          {parseInt(
                            item.PREMIUM_DATA.PREMIUM_DATA?.TOTAL_PREMIUM
                          ).toLocaleString("en-IN")}
                          <Check
                            labelStyle={{
                              marginRight: 0,
                            }}
                            checkBoxStyle={{
                              padding: 0,
                              marginLeft: "12px",
                            }}
                            primary
                            checked={quotesToShare.some(
                              (quote) =>
                                quote.PRODUCT_DATA.PRODUCTID ===
                                item.PRODUCT_DATA.PRODUCTID
                            )}
                            onClick={() => {
                              if (
                                quotesToShare.some(
                                  (quote) =>
                                    quote.PRODUCT_DATA.PRODUCTID ===
                                    item.PRODUCT_DATA.PRODUCTID
                                )
                              ) {
                                setQuotesToShare(
                                  quotesToShare.filter(
                                    (quote) =>
                                      quote.PRODUCT_DATA.PRODUCTID !==
                                      item.PRODUCT_DATA.PRODUCTID
                                  )
                                );
                                return;
                              }
                              setQuotesToShare([...quotesToShare, item]);
                            }}
                          />
                        </Button>
                      </ShareQuotesSection>
                    </ShareQuotesWrapperRow>
                    <ShareQuotesWrapperRow>
                      <Label> Cover Value :</Label>
                      <Value>
                        {" "}
                        ₹{" "}
                        {parseInt(
                          item.PREMIUM_DATA.PREMIUM_DATA?.IDV
                        ).toLocaleString("en-IN")}
                      </Value>
                    </ShareQuotesWrapperRow>
                  </ShareQuotesWrapperMobile>
                </>
              }
            </>
          ))}
        </div>
      )}
    </>
  );
};

export default ShareSelectedQuotes;

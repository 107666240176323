import {
  CircularProgress,
  Dialog,
  Divider,
  makeStyles,
  useMediaQuery,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button } from "../../../../../components/Button/Button";
import RadioButton from "../../../../../components/RadioButton/RadioButton";
// import { useAddonsFromUrl } from "../../../../../hooks/addonsFromUrl";
import { useEnquiryQuery } from "../../../../../services/CarServices";
import { useFilterMutation } from "../../../../../services/QuoteServices";
import { IDVrangeSelector } from "../../../QuotesPage.slice";
import {
  ButtonsWrapper,
  CloseButton,
  DialogTitle,
  FormWrapper,
} from "../../EditDetailsDialog/EditDetailsDialog.style";
import { CustomValueText, CustomWrapper, RadioWrapper } from "../Filters.style";
import { allowOnlyPrice } from "./../../../../../utils/allowOnlyNumber";
const useStyles = makeStyles({
  paper: {
    padding: "0 12px",
  },
});
const IDV = ({ open, onClose }) => {
  const { update, isLoading, isSuccess, quote_id } = useFilterMutation();
  const { requestData } = useEnquiryQuery();
  const navigate = useNavigate();
  // const prevAddon = useAddonsFromUrl();
  useEffect(() => {
    if (isSuccess) {
      // navigate(`/quotes?quote_id=${quote_id}&${prevAddon}`, { replace: true });
      onClose();
    }
  }, [isSuccess, quote_id]);
  const fullScreen = useMediaQuery("(max-width:576px)");
  const classes = useStyles();
  const { min, max } = useSelector(IDVrangeSelector);
  const [value, setValue] = useState();
  const range = {
    LIDV: min,
    HIDV: max,
    RIDV: Math.ceil((min + max) / 2),
    C: value,
  };
  const [IDV, setIDV] = useState("C");
  const [error, setError] = useState();
  const onSubmit = () => {
    if ((IDV === "C" && !error) || IDV)
      update({ ...requestData, IDV: range[IDV] });
  };
  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        fullWidth
        maxWidth={"xs"}
        fullScreen={fullScreen}
        classes={classes}
        disableEnforceFocus
      >
        <DialogTitle>
          <div>Insured Value(IDV)</div>
          <CloseButton onClick={() => onClose()}>
            <Close />
          </CloseButton>
        </DialogTitle>
        <Divider />
        <FormWrapper>
          <RadioWrapper>
            <RadioButton
              col
              label='Set by'
              defaultValue={IDV || "C"}
              items={[
                {
                  label: `Lowest IDV (₹ ${min.toLocaleString("en-IN")})`,
                  value: "LIDV",
                },
                {
                  label: `Recommended IDV (₹ ${Math.ceil(
                    (min + max) / 2
                  ).toLocaleString("en-IN")})`,
                  value: "RIDV",
                },
                {
                  label: `Highest IDV (₹ ${max.toLocaleString("en-IN")})`,
                  value: "HIDV",
                },
                { label: "Choose Your IDV", value: "C" },
              ]}
              onChange={(val) => setIDV(val)}
            />
            {IDV === "C" && (
              <CustomWrapper>
                <CustomValueText
                  type='tel'
                  onInput={allowOnlyPrice}
                  maxLength={14}
                  onChange={(e) => {
                    const num = parseInt(e.target.value.replace(/,|\s|₹/g, ""));
                    console.log(num, min, max);
                    if (num < min || num > max) {
                      setError(true);
                    } else setError(false);
                    setValue(num);
                  }}
                  error={error}
                  placeholder={`${min}-${max}`}
                />
              </CustomWrapper>
            )}
          </RadioWrapper>
          <ButtonsWrapper>
            <Button secondary onClick={() => onClose()}>
              Cancel
            </Button>
            <Button primary onClick={onSubmit}>
              {isLoading ? (
                <CircularProgress size={"16px"} style={{ color: "#fff" }} />
              ) : (
                "Apply"
              )}
            </Button>
          </ButtonsWrapper>
        </FormWrapper>
      </Dialog>
    </>
  );
};

export default IDV;

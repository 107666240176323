import React from "react";
import "styled-components/macro";
const FormHeading = () => {
  return (
    <>
      {" "}
      <div
        css={`
          margin-bottom: 45px;
          @media (max-width: 991px) {
            text-align: center;
          }
        `}
      >
        <h2
          css={`
            font-size: 1.5rem;
            font-weight: 700;
            text-transform: capitalize;
            @media (max-width: 576px) {
              font-weight: 600;
              font-size: 1.5rem;
            }
          `}
        >
          Safeguard your car in{" "}
          <span
            css={`
              font-size: 1.75rem;
              font-weight: 800;
              text-transform: capitalize;
              color: var(--secondary-color);
              @media (max-width: 576px) {
                font-weight: 600;
                font-size: 1.25rem;
              }
            `}
          >
            5
          </span>{" "}
          minutes
        </h2>
        <p
          css={`
            @media (max-width: 576px) {
              font-size: 14px;
            }
          `}
        >
          Discuss all your questions with our experts
        </p>
      </div>
    </>
  );
};

export default FormHeading;
